import { useEffect, useState, useCallback, useContext } from 'react';
// REDUX IMPORTS
import { useSelector, useDispatch } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
// NPM IMPORTS
import _ from 'lodash';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack5';
// MATERIAL IMPORTS 
import {
    Box,
    Table,
    Switch,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Button,
    Avatar,
    CircularProgress,
    TableContainer,
    TablePagination,
    tableCellClasses,
    FormControlLabel,
    Stack,
    Typography,
    Divider,
    OutlinedInput, InputAdornment,
    Pagination

} from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core/styles';
import { makeStyles } from '@mui/styles';
// COMPONENT IMPORTS
import { MIconButton } from '../../../../../components/@material-extend';
import Scrollbar from '../../../../../components/Scrollbar';
import SortingSelectingHeadScore from './sortSelectingHeadStaff';
import ScoreWrapper from '../../../../scorecomponent/scorewrapper';
import { PATH_DASHBOARD, PATH_EMP } from "../../../../../routes/paths";
// REDUX ACTION IMPORTS
import { delGroup, getAllGroups, searchAllGroups } from '../../../../../redux/action/staffAction';
import { getScoreList, getScoreSearch } from '../../../../../redux/action/scoreAction';
// API IMPORTS
import { grpDraftListApi } from "../../../../../_apis_/authApi.js/empapi";
// CONTEXT IMPORTS
import { SettingsContext } from "../../../../../contexts/SettingsContext";



// ----------------------------------------------------------------------



const TABLE_HEAD = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'ID'
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Scorecard Name'
    },
    {
        id: 'groupName',
        numeric: false,
        disablePadding: false,
        label: 'Group'
    },
    {
        id: 'category',
        numeric: false,
        disablePadding: false,
        label: 'Category'
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description'
    },
    {
        id: 'location',
        numeric: false,
        disablePadding: false,
        label: 'Location'
    },

];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}



function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        // console.log("query", array);

        // return filter(array, (data) =>
        //     data.name.toLowerCase().includes(query.toLowerCase()) !== false ||
        //     data.description.toLowerCase().includes(query.toLowerCase()) !== false ||
        //     data.id.toLowerCase().includes(query.toLowerCase()) !== false ||
        //     data.location.toLowerCase().includes(query.toLowerCase()) !== false


        // )
    }
    return stabilizedThis?.map((el) => el[0]);
}

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {


        width: 210,
        height: 45,


        '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },


        '& .MuiOutlinedInput-input::placeholder': {
            color: theme.palette.text.buttonText, // Change this color to your desired placeholder color,
            fontFamily: 'Nunito, sans-serif'
        }
    },
    [theme.breakpoints.down('sm')]: {


        width: 150,
        height: 35,


        '&.Mui-focused': { width: 150, boxShadow: theme.customShadows.z8 },

        '& .MuiOutlinedInput-input::placeholder': {
            fontSize: '12px',
            color: theme.palette.text.buttonText, // Change this color to your desired placeholder color,
            fontFamily: 'Nunito, sans-serif'
        }
    },
    color: '#5F6368 !important',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
        border: 'none !important',
        color: '#5F6368 !important', fontWeight: '100 !important'
    },
}));

const useStyles = makeStyles({
    pagination: {
        '& .MuiTablePagination-actions > button:first-of-type': {
            display: 'none', // Hide the "Previous" button
        },
        '& .MuiTablePagination-actions > button:last-of-type': {
            display: 'none', // Hide the "Next" button
        },
        '& .MuiTablePagination-toolbar': {
            paddingLeft: 1,
        },
    },
    paginationText: {
        color: '#212668', // Change this to the color you want,
        fontWeight: '600 !important'
    }
});


export default function SelectScoreCardTable() {
    const classes = useStyles();
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);
    // REACT REDUX STATES
    const group = useSelector(state => state.scoreList);
    const dispatch = useDispatch();
    // REACT ROUTER STATES
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    // CONFIRM STATES
    const confirm = useConfirm();
    // SNACBAR STATES
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // CONTEXT STATES
    const { setColor, themeMode, showSidebar } = useContext(SettingsContext);
    // REACT STATES
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filterName, setFilterName] = useState('');
    const [draftInfo, setDraftInfo] = useState([]);
    const [tableOrder, setTableOrder] = useState(false);
    const [pageRange, setPageRange] = useState(0);


    useEffect(() => {
        setIsLoading(true);
        dispatch(getScoreList(searchParams.get("empId"), 1))
        if (loggedUserData?.staff?.staffType === 10) {
            getDraftData();
        }
    }, [])

    const chckTimeOut = () => {

        if (isLoading && data?.length === 0) {
            setIsLoading(false);
        }

    }

    setTimeout(() => {
        chckTimeOut();
    }, 60000);

    const getDraftData = async () => {
        const { data } = await grpDraftListApi(1, 20);
        setDraftInfo(data?.results);

    }


    useEffect(() => {
        if (group?.results !== undefined) {
            console.log(group)
            setIsLoading(false);
            setData(group?.results);
            setDataCount(group?.count);
            setPageRange(group?.pageRange?.length)
        }
    }, [group])



    const mbLink = [];


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setData([]);
        setIsLoading(true);
        if (filterName && filterName !== ("" || null)) {
            dispatch(getScoreSearch(searchParams.get("empId"), event.target.value, newPage))
            return setPage(newPage);
        }
        setPageRange(0)
        dispatch(getScoreList(searchParams.get("empId"), newPage))
        return setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const sendQuery = (queryText) => {
        if (queryText?.length === 0) {
            return dispatch(getScoreList(searchParams.get("empId"), 1))
        }
        if (queryText?.length > 0) {
            return dispatch(getScoreSearch(searchParams.get("empId"), queryText, 1))
        }
    }

    const delayedQuery = useCallback(_.debounce((query) => sendQuery(query), 1000), []);

    const handleFilterByName = (event) => {
        setData([]);
        setIsLoading(true);
        setPage(1);
        setPageRange(0)
        setFilterName(event.target.value);
        delayedQuery(event.target.value);

    };

    switch (loggedUserData?.staff?.staffType) {
        case 10:
            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: `${PATH_DASHBOARD.general.score.selectScorecard}?empId=${searchParams.get("empId")}&grpId=${searchParams.get("grpId")}&sup_dash=${searchParams.get("sup_dash")}` }, { name: 'Select Scorecards' })
            break;
        // case 20 || 30:
        //     console.log(searchParams.get("sup_dash"))
        //     if (searchParams.get("sup_dash") === "True") {
        //         mbLink.push({ name: 'Home', href: "/supervisor/dashboard" }, { name: "Select Groups", href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join("")}&sup_dash=True` }, { name: 'Select Scorecards' })
        //     }
        //     if (searchParams.get("sup_dash") === "False") {
        //         mbLink.push({ name: 'Home', href: PATH_EMP?.dashboard }, { name: "Select Groups", href: `/score/select_scorecard?empId=${searchParams.get("empId")}&sup_dash=False` }, { name: 'Select Scorecards' })

        //     }
        //     break;
        case 20 || 30:
            console.log(searchParams.get("sup_dash"))
            if (searchParams.get("sup_dash") === "True") {
                mbLink.push({ name: 'Home', href: "/supervisor/dashboard" }, { name: "Select Groups", href: `${PATH_DASHBOARD.general.score.selectEmployee}?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Employees', href: `/score/select_emp?id=${grpArr?.join(",")}&sup_dash=True` }, { name: 'Select Scorecards' })
            }
            if (searchParams.get("sup_dash") === "False") {
                mbLink.push({ name: 'Home', href: PATH_EMP?.dashboard }, { name: "Select Groups", href: `${PATH_DASHBOARD.general.score.selectScorecard}?empId=${searchParams.get("empId")}&sup_dash=False` }, { name: 'Select Scorecards' })

            }
            break;

        default:
            mbLink.push({ name: 'Home', href: PATH_DASHBOARD.general.dashboard }, { name: "Select Groups", href: PATH_DASHBOARD.general.scoreGrp }, { name: 'Select Employees', href: `${PATH_DASHBOARD.general.score.selectEmployee}?id=${searchParams.get("grpId")}` }, { name: 'Select Scorecards' })
            break;
    }

    // async function handleDelGroup(id) {

    //     await confirm({ description: 'This action is permanent! It will permanently delete the group. ' })
    //     const res = await delGroupAPI(id);

    //     dispatch(delGroup(id))
    //     enqueueSnackbar('Deleted Group Successfully', {
    //         variant: 'success',
    //         action: (key) => (

    //             <MIconButton size="small" onClick={() => closeSnackbar(key)}>
    //                 <Icon icon={closeFill} />
    //             </MIconButton>
    //         )
    //     });

    // }

    const handleTableOrder = () => {
        setData(data.reverse())
        setTableOrder(!tableOrder)
    }

    const getEmpTableParameter = () => {
        if (loggedUserData?.staff?.staffType === 10) {
            return JSON.stringify({ staff_type: 10, teams: loggedUserData.teams?.map(item => ({ id: item?.id, name: item?.name })), name: `${loggedUserData?.firstName} ${loggedUserData?.lastName}`, supervisor_id: loggedUserData?.staff?.supervisor })
        }
        return searchParams.get('empTable')
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;


    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);

    const getColor = (type) => {
        if (type?.toLowerCase() === 'inbound call') {
            return { bgColor: '#F8F1B0', color: '#8B7D00', border: '1px solid #8B7D00' }; // Medium value: yellow
        }

        return { bgColor: '#D9E2EF', color: '#212668', border: '1px solid #676A9D' };
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

    return (
        <>

            <ScoreWrapper title="Select Scorecards" fnDraft={getDraftData} draftData={draftInfo} link={mbLink} style={{ width: ((loggedUserData?.staff?.staffType === 10 || searchParams.get('stff_sup_id')) && showSidebar) ? '95%' : '100%', ml: ((loggedUserData?.staff?.staffType === 10 || searchParams.get('stff_sup_id')) && showSidebar) ? '70px' : '0px' }} >
                <Stack sx={{ width: "100%" }} direction="row" alignItems='center' alignContent="center" justifyContent="space-between">
                    <Typography variant="body2" sx={{ fontWeight: "bold", color: "#576574" }}>* Only locked scorecard is listed.</Typography>
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} placeholderName="Search Group..." /> */}
                    <SearchStyle
                        value={filterName}
                        onChange={handleFilterByName}
                        placeholder="Search Scorecards..."
                        startAdornment={
                            <>
                                <InputAdornment position="start">
                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            </>
                        }
                    />
                    {/* <Stack direction="row" alignItems="center" alignContent="center">
                        <Typography variant="body2" >Row PerPage:&nbsp;100</Typography>
                        <TablePagination
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={[100]}
                            component="div"
                            count={dataCount || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack> */}
                </Stack>
                {/* <Divider /> */}
                <Scrollbar>
                    <TableContainer sx={{ minWidth: "800px", py: 2 }}>
                        <Table size={dense ? 'small' : 'medium'}>
                            <SortingSelectingHeadScore
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                rowCount={data?.length}
                                onSelectAllClick={handleSelectAllClick}
                                tableOrder={tableOrder}
                                handleTableOrder={handleTableOrder}
                            />
                            <TableBody>
                                {filteredUsers?.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{
                                                [`& .${tableCellClasses.root}`]: {
                                                    borderBottom: (theme) => `1px solid ${(index !== data?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                                },
                                            }}
                                        >
                                            <TableCell scope="row" align="center" >
                                                <Box sx={{ width: "100%" }}>
                                                    <Typography sx={{ fontSize: '14px', align: "center", marginLeft: "3px", marginRight: '5px' }}>{row.id}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align='center' id={labelId} scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Link
                                                        to={`/score/reviewPerformance?gpId=${searchParams.get("grpId")}&empId=${searchParams.get("empId")}&score_card_Id=${row.id}&toScore=True&empTable=${getEmpTableParameter()}`}
                                                        style={{ color: '#626262', cursor: 'pointer', textDecoration: 'underline', fontSize: '1rem' }}>

                                                        <Typography sx={{ fontSize: { xs: '12px', sm: '14px' }, fontWeight: 'bold' }} >
                                                            {row?.name}
                                                        </Typography>


                                                    </Link>
                                                </Box>

                                            </TableCell>

                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Typography sx={{ fontSize: { xs: '12px', sm: '14px' } }} >
                                                        {row?.group_name}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Button
                                                    style={{
                                                        padding: '0px 15px',
                                                        fontWeight: '400',
                                                        marginRight: '5px',
                                                        marginBottom: '2px',
                                                        fontSize: '12px',
                                                        width: '100px',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: '100px',
                                                        minWidth: '100px',
                                                        height: '25px'
                                                    }}
                                                    // variant="outlined"
                                                    sx={{
                                                        // backgroundColor: getColor(row?.type).bgColor,
                                                        color: getColor(row?.category).color,
                                                        border: getColor(row?.category).border
                                                    }}
                                                >{row?.category}</Button>
                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Typography sx={{ fontSize: { xs: '12px', sm: '14px' } }} >
                                                        {row?.description}
                                                    </Typography>

                                                </Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row" padding="normal">
                                                <Box sx={{ width: "100%" }}>
                                                    <Typography sx={{ fontSize: { xs: '12px', sm: '14px' } }} >
                                                        {row?.location}
                                                    </Typography>

                                                </Box>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                {data?.length === 0 && (
                                    <TableRow>

                                        {isLoading ? <TableCell colSpan={12}>
                                            <Box display="flex" justifyContent="center" alignContent="cneter" sx={{ py: 2 }}>
                                                <CircularProgress /> Loading Scorecard...
                                            </Box>
                                        </TableCell> : <TableCell colSpan={12}>
                                            <Typography gutterBottom align="center" variant="subtitle1" sx={{ py: 2 }}>
                                                No Scorecard Found
                                            </Typography>
                                        </TableCell>}

                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <>
                        <Typography variant="body2" sx={{ fontSize: '16px', paddingLeft: '35px' }}>
                            Showing
                        </Typography>
                        <TablePagination
                            classes={{ root: classes.pagination, displayedRows: classes.paginationText, }}
                            sx={{ border: "1px solid transparent" }}
                            rowsPerPageOptions={[100]}
                            component="div"
                            count={dataCount || 0}
                            rowsPerPage={rowsPerPage}
                            page={(page - 1)}
                        // onPageChange={handleChangePage}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                    <Pagination sx={{ ml: 'auto' }} count={pageRange} page={page} onChange={handleChangePage} variant="outlined" shape="rounded" />
                </Box>
            </ScoreWrapper >
        </>
    );
}