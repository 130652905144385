import React from 'react';
// REDUX IMPORTS
import { useSelector } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box,
    Button,
    Chip,
    IconButton,
    Paper,
    Table,
    TableBody,
    Typography,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    tableCellClasses,
    Tooltip
} from '@material-ui/core';
import { Flag, WarningAmber, Star, Score, ArrowDownward, ArrowUpward, Visible, Visibility } from '@material-ui/icons';

export default function ScoreSummary({ theme, themeColor }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REDUX STATES
    const summary = useSelector(state => state.empSummary);
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    // REACT STATES
    const [scorecard, setscorecard] = React.useState([]);
    const [showIcon, setShowIcon] = React.useState(false);
    const [tableOrder, setTableOrder] = React.useState(false);

    const handleTableOrder = () => {
        setTableOrder(!tableOrder)
        setscorecard(scorecard.reverse());
    }

    React.useEffect(() => {
        if (summary) {
            setscorecard(summary.score_scorecard);
            // console.log(summary.score_scorecard);
        }
    }, [summary])
    return (
        <>
            <TableContainer >
                <Table size='small' sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{
                            [`& .${tableCellClasses.root}`]: {
                                py: 1
                            },
                        }}>
                            <TableCell onMouseLeave={() => setShowIcon(false)} onMouseEnter={() => setShowIcon(true)}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>
                                        Scorecards
                                    </Typography>&nbsp;
                                    {showIcon && <Tooltip title='sort' ><IconButton onClick={() => handleTableOrder()} >{tableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}</IconButton></Tooltip>}
                                </Box>

                            </TableCell>
                            <TableCell align="center"><Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>Reviewed</Typography></TableCell>
                            <TableCell align="center"><Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>1:1</Typography></TableCell>
                            <TableCell align="center"><Flag /></TableCell>
                            <TableCell align="center"><WarningAmber /></TableCell>
                            <TableCell align="center"><Star /></TableCell>
                            <TableCell align="center"><Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>Team Score</Typography></TableCell>
                            <TableCell align="center"><Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>My Score</Typography></TableCell>
                            <TableCell align="center">
                                <Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>Action</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scorecard?.length > 0 &&
                            scorecard?.map((obj, index) =>
                                <TableRow
                                    key={index}
                                    sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: (theme) => `1px solid ${(index !== scorecard?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                        },
                                    }}
                                >
                                    <TableCell align="left" component="th" scope="row">
                                        {obj.score_card__name}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.reviewed}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.onetoone_req}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.fail_section_count}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.fail_all_count}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {obj.kudos}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {(obj?.team_score_sum - obj?.team_na_sum) === 0 && <>0.00%</>}
                                        {(obj?.team_score_sum - obj?.team_na_sum) !== 0 && <>
                                            {`${Number?.isNaN(((obj?.team_total_score / (obj?.team_score_sum - obj?.team_na_sum)) * 100)?.toFixed(2)) ? 0 : ((obj?.team_total_score / (obj?.team_score_sum - obj?.team_na_sum)) * 100)?.toFixed(2)}%`}

                                        </>}

                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Chip label={Number?.isNaN(((100 * obj?.total_score) / obj.max_score)) ? `0 %` : `${((100 * obj?.total_score) / obj.max_score).toFixed(1)}%`} sx={{ "& .MuiChip-label": { color: '#CF9C07' }, backgroundColor: '#FFEDB8', fontSize: 'bold', color: 'white', minHeight: '30px', maxHeight: '30px', minWidth: '78px', maxWidth: '78px' }} />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {loggedUserData?.staff?.staffType === 10 && <Link to={`/employee/scores?tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <IconButton size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px' }} >
                                                <Visibility size='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                            </IconButton>
                                        </Link>}
                                        {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={`/employee/scores?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=False&tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <IconButton size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px' }} >
                                                <Visibility size='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                            </IconButton>
                                        </Link>}
                                        {!loggedUserData?.staff && <Link to={`/employee/scores?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=True&tab=${index + 1}`} style={{ textDecoration: "none" }}>
                                            <IconButton size='small' variant="contained" sx={{ backgroundColor: '#F1F2FF', borderRadius: '5px' }} >
                                                <Visibility size='small' sx={{ color: '#F1F2FF', stroke: '#212668' }} />
                                            </IconButton>
                                        </Link>}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
                {scorecard?.length === 0 && <Paper elevation={0} sx={{ minHeight: "100px", width: "100%", p: 2 }}>
                    <Typography gutterBottom align="center" variant="subtitle1" sx={{ p: 2, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Score /> &nbsp;No Data Found For Data Range Selected.
                    </Typography>
                </Paper>}
            </TableContainer>

        </>
    )
}