import { Link } from 'react-router-dom';
import { Typography, Stack } from '@material-ui/core';

export default function BoxHeading({ title, linkTitle, link }) {
    return (<>
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 1, px: 1 }} >

            <Typography sx={{ fontWeight: 'bold', fontSize: { sm: '14px', xs: '12px' } }}>{title}</Typography>
            <Link to={link}>
                <Typography sx={{ fontWeight: 'bold', fontSize: { sm: '14px', xs: '12px' } }}>
                    {linkTitle}
                </Typography>
            </Link>

        </Stack>
    </>)
}