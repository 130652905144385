import React from 'react';
// REDUX IMPORTS
import { useSelector, useDispatch } from 'react-redux';
// REACT ROUTER IMPORTS
import { Link, useSearchParams } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box,
    CircularProgress,
    Divider,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
    Tooltip
} from '@material-ui/core';
import { Assignment, Check, Comment, ThumbUp, Announcement, ArrowDownward, ArrowUpward } from '@material-ui/icons';
// NPM IMPORTS
import moment from "moment";
// COMPONENT IMPORTS
import EmpDatePicker from "./datepicker";
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_EMP } from '../../routes/paths';
// REDUX ACTION IMPORTS
import { getactivity } from '../../redux/action/empAction';
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';

export default function EmpActivity() {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT ROUTER STATE
    const [searchParams, setSearchParams] = useSearchParams();
    // REDUX STATE
    const dispatch = useDispatch();
    const activity = useSelector(state => state?.empActivity);
    // REACT STATE
    const [data, setData] = React.useState([]);
    const { datesData, setDatesData } = React.useContext(EmpContext);
    const [page, setPage] = React.useState(0);
    const [date, setDate] = React.useState(moment(new Date()).format("YYYY, DD, MM"));
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [isDataLoading, setIsDataLoading] = React.useState(false);
    const [tableOrder, setTableOrder] = React.useState(false);
    const [showIcon, setShowIcon] = React.useState(false)

    const loadingTimeOut = () => {
        setTimeout(() => {
            if (isDataLoading) {
                setIsDataLoading(false);
            }
        }, 60000);

    }

    const handleTableOrder = () => {
        setData(data?.reverse())
        setTableOrder(!tableOrder);
    }

    const handleChangePage = (event, newPage) => {
        setIsDataLoading(true);
        loadingTimeOut();
        setData([]);
        if (newPage === 0) {
            dispatch(getactivity(datesData?.date1, datesData?.date2, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, 1, false));
            return setPage(newPage);
        }
        dispatch(getactivity(datesData?.date1, datesData?.date2, searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, newPage + 1, false));
        return setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        setIsDataLoading(true);
        loadingTimeOut();
        setData([]);
        setPage(0)

    }, [datesData])

    React.useEffect(() => {
        setIsDataLoading(true);
        loadingTimeOut();
        if (activity?.result !== undefined) {
            const newArr = activity?.result;
            setData(newArr?.reverse());
            setIsDataLoading(false);
        }
    }, [activity])



    return (
        <Box sx={{ px: 1 }}>
            <Stack sx={{ p: 0, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0 }} direction='row' alignContent='center' alignItems='center' justifyContent='space-between'>
                <Box>

                    <HeaderBreadcrumbs
                        sx={{ pr: 3, mb: 0, color: '#666666', fontWeight: 600 }}
                        links={[{ name: 'Home', href: searchParams.get('stff_emp_id') ? `${PATH_EMP.dashboard}?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : PATH_EMP.dashboard }, { name: 'Activity', href: PATH_EMP.activity }]}
                    />
                    <Typography variant='h4' sx={{ mt: 0, mb: 2, fontWeight: '400', color: '#000000' }}>
                        All Activity
                    </Typography>
                </Box>
                <EmpDatePicker />
            </Stack>



            {/* <Box sx={{ px: 5, py: 1, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }} display="flex" justifyContent="flex-end" alignItems="center">
                    <Typography variant="h4">All Activity</Typography>
                    <EmpDatePicker />
                </Box>  */}
            {/* <Divider /> */}
            <  Box component={Paper} elevation={5} sx={{ p: 2, borderRadius: '5px', my: 4 }}>
                {/* <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ pr: 2, py: 0, borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }}>
                        <Typography variant="body2" sx={{ p: 0, m: 0 }} >Row PerPage:&nbsp;100</Typography>
                        <TablePagination
                            sx={{ border: "1px solid transparent", py: 0, my: 0 }}
                            rowsPerPageOptions={100}
                            component="div"
                            count={activity?.counts || 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack> */}
                {/* <Divider /> */}

                {data?.length > 0 && <TableContainer component={Paper} elevation={0} sx={{ overflow: 'auto', '&::-webkit-scrollbar': { borderRadius: 5, width: '8px' }, '&::-webkit-scrollbar-track': { borderRadius: 5, backgroundColor: '#dcdde1', overflow: 'hidden' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#95a5a6', borderRadius: 5 } }}>
                    <Table size='small' sx={{ width: "100%" }} aria-label="simple table">
                        <TableBody>
                            {data?.map(row => <TableRow
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        py: 3
                                    }, '&:last-child td, &:last-child th': { border: 0 }
                                }}
                            >
                                <TableCell align="left" component="th" scope="row" sx={{ width: "100%" }}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box display="flex" justifyContent="space-between" alignItems="center">

                                            {(row.type === 10 || row.type === 20) && <Check sx={{ mr: 1 }} />}
                                            {row.type === 30 && <ThumbUp sx={{ mr: 1 }} />}
                                            {row.type === 40 && <Comment sx={{ mr: 1 }} />}
                                            {row.type === 50 && <Announcement sx={{ mr: 1 }} />}
                                            <Box>
                                                <Typography sx={{ fontSize: "13px" }}>
                                                    {row?.action}
                                                </Typography>
                                                <h6 style={{ fontSize: "12px" }}>
                                                    {/* {moment(new Date()).format("YYYY, DD, MM").diff(moment(row?.created_at).format("YYYY, DD, MM"))}
                                                {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days')} */}

                                                    {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 1 && "Today"}
                                                    {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') === 1 && "1 Day Ago"}
                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 7 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') > 1) && `${moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days')} days ago`}
                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 7 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 14) && "1 week ago"}
                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 14 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 28) && "2 week ago"}
                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 28 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 30) && "3 week ago"}
                                                    {(moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 30 && moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') < 60) && "1 month ago"}
                                                    {moment(new Date(), "LL").diff(moment(row?.created_at, "LL"), 'days') >= 60 && moment(row?.created_at).format("LL")}
                                                </h6>
                                            </Box>

                                        </Box>

                                    </Box>
                                    <Divider sx={{ my: 2 }} />
                                </TableCell>

                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>}
                {data?.length === 0 && <Box sx={{ width: "90%", minHeight: "200px", py: 2 }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                    <Assignment sx={{ fontSize: "100px" }} /><br />
                    {isDataLoading ? <Stack direction="row" alignItems="center" justifyContent="center">
                        <CircularProgress />&nbsp;
                        <Typography variant="body" sx={{ my: 1 }}>

                            Loading Data...
                        </Typography>
                    </Stack> : <Typography variant="body" sx={{ my: 1 }}>
                        No Activity found for the data range selected.
                    </Typography>}
                </Box>}
            </Box>

        </Box>
    )
}