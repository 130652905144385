import React from 'react';
// REACT ROUTER STATES
import { Link, useSearchParams } from 'react-router-dom';
// MATERIAL IMPORTS
import {
    Box,
    Button,
    Chip,
    IconButton,
    Paper,
    Stack,
    Table,
    tableCellClasses,
    TableBody, TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@material-ui/core';
import { Comment, ArrowUpward, ArrowDownward } from '@material-ui/icons';
// NPM IMPORTS
import PropTypes from 'prop-types';
import { Interweave } from 'interweave';
import moment from "moment";
// COMPONENT IMPORTS
import AcceptComment from "../commentDialog";
import AcceptOne2One from "../one2oneDialog";
// UTIL IMPORTS
import { setButtonBg, setButtonText } from "../../../utils/filterUtilities";
// CONTEXT IMPORTS
import { EmpContext } from '../../../contexts/employeecontext';
// API IMPORTS
import { empCommentListApi } from "../../../_apis_/authApi.js/empapi";
// ------------------------------------------------------------------------------------

CommentSummary.propTypes = {
    theme: PropTypes.string,
    themeColor: PropTypes.object
}


export default function CommentSummary({ theme, themeColor }) {
    // LOCAL STORAGE
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    // CONTEX STATES
    const { datesData, setAcceptReview } = React.useContext(EmpContext);
    // REACT STATES
    const [id, setId] = React.useState();
    const [comment, setComment] = React.useState([]);
    // const [date, setDate] = React.useState(moment(new Date()).format("YYYY, DD, MM"));
    const [tableOrder, setTableOrder] = React.useState(false);
    const [showIcon, setShowIcon] = React.useState(false);

    const handleTableOrder = () => {
        setComment(comment?.reverse());
        setTableOrder(!tableOrder);
    }

    async function getComments() {
        // LOCAL STORAGE STATE
        const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
        const { data } = await empCommentListApi(searchParams.get("stff_emp_id") || loggedUserData?.staff?.id, datesData?.date1, datesData?.date2, 5);
        setComment(data?.results)
        console.log(data, data?.results)
    }
    React.useEffect(() => {
        if (datesData?.date1) {
            console.log(datesData)
            getComments();
        }
    }, [datesData])
    return (
        <>
            <TableContainer sx={{ minHeight: '300px', maxHeight: '300px', '&::-webkit-scrollbar': { borderRadius: 5, width: '8px' }, '&::-webkit-scrollbar-track': { borderRadius: 5, backgroundColor: '#dcdde1', overflow: 'hidden' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#95a5a6', borderRadius: 5 } }}>
                <Table stickyHeader size='small' sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{
                            [`& .${tableCellClasses.root}`]: {
                                py: 1
                            },
                        }}>
                            <TableCell onMouseEnter={() => setShowIcon(true)} onMouseLeave={() => setShowIcon(false)} >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "14px", color: theme => theme.palette.text.primary }}>
                                        Comments
                                    </Typography>
                                    {showIcon && <Tooltip title='sort'>
                                        <IconButton onClick={() => handleTableOrder()}>
                                            {tableOrder === false ? <ArrowDownward fontSize='small' /> : <ArrowUpward fontSize='small' />}
                                        </IconButton>
                                    </Tooltip>}
                                </Box>

                            </TableCell>
                            <TableCell align="right">Scores</TableCell>
                            {(comment?.some((row, index) => row?.score_reviewed) || (!comment?.some((row, index) => !row?.score_reviewed) && loggedUserData?.staff?.staffType !== 10 && loggedUserData?.userLevel?.name !== 'Dashboard Only')) && <TableCell align='center'>
                                Action
                            </TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            comment?.length > 0 && comment?.map((row, index) => <TableRow
                                key={index}
                                sx={{
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: (theme) => `1px solid ${(index !== comment?.length - 1) ? `${theme.palette.text.primaryBorder}` : 'transparent'}`,
                                    },
                                }}
                            >
                                <TableCell align="left" component="th" scope="row" sx={{ width: "50%" }}>
                                    <Box>
                                        <Box>
                                            <Typography sx={{ fontSize: "14px" }}>
                                                {row?.scorecard_score}
                                            </Typography>
                                        </Box>
                                        <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                                            {row?.is_score_accepted && <Stack direction="flow"><h4>Accepted-</h4> <Interweave content={row.comments} /></Stack>}
                                            {row?.is_onetoone_req && <Stack direction="flow"><h4>1:1 Req-</h4> <Interweave content={row.comments} /></Stack>}
                                            {row?.is_score_reviewed && <Stack direction="flow"><h4>Reviewed-</h4> <Interweave content={row.comments} /></Stack>}
                                            {(row?.is_score_accepted === false && row?.is_score_reviewed === false && row?.is_onetoone_req === false) && <Interweave content={row.comments} />}
                                        </Typography>
                                        <Typography sx={{ fontSize: "11px", fontWeight: "normal", mt: 1 }}>
                                            <p>{row?.commented_by}</p>
                                            {moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 1 && "Today"}
                                            {moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') === 1 && "1 Day Ago"}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 7 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') > 1) && `${moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days')} days ago`}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 7 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 14) && "1 week ago"}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 14 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 28) && "2 week ago"}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 28 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 30) && "3 week ago"}
                                            {(moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 30 && moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') < 60) && "1 month ago"}
                                            {moment(new Date(), "LL").diff(moment(row?.created_at).format("LL"), 'days') >= 60 && moment(row?.created_at).format("LL")}
                                        </Typography>

                                    </Box>
                                </TableCell>
                                <TableCell align="right" component="th" scope="row">
                                    <Chip label={`${row?.percentage}%`} sx={{ "& .MuiChip-label": { color: setButtonText(row?.score_result) }, backgroundColor: setButtonBg(row?.score_result), fontSize: 'bold', color: 'white', minHeight: '30px', maxHeight: '30px', minWidth: '78px', maxWidth: '78px' }} />

                                    {/* {(100 * row?.total_score) / row?.max_score > 75 && <Button disableRipple sx={{ py: "2px", px: "4px", fontSize: "12px", backgroundColor: "#55efc4", cursor: "default", color: "white", "&:hover": { backgroundColor: "#55efc4" } }} variant="contained">{`${((100 * row?.total_score || 0) / row?.max_score || 0).toFixed(1)}%`}</Button>}
                                    {((100 * row?.total_score) / row?.max_score > 60 && (100 * row?.total_score) / row?.max_score < 75) && <Button disableRipple sx={{ py: "2px", px: "4px", fontSize: "12px", backgroundColor: "#feca57", cursor: "default", color: "white", "&:hover": { backgroundColor: "#feca57" } }} variant="contained">{`${((100 * row?.total_score || 0) / row?.max_score || 0).toFixed(1)}%`}</Button>}
                                    {(100 * row?.total_score) / row?.max_score < 60 && <Button disableRipple sx={{ py: "2px", px: "4px", fontSize: "12px", backgroundColor: "#e74c3c", cursor: "default", color: "white", "&:hover": { backgroundColor: "#e74c3c" } }} variant="contained">{`${((100 * row?.total_score || 0) / row?.max_score || 0).toFixed(1)}%`}</Button>} */}
                                </TableCell>
                                {(comment?.some((row, index) => row?.score_reviewed) || (!comment?.some((row, index) => !row?.score_reviewed) && loggedUserData?.staff?.staffType !== 10 && loggedUserData?.userLevel?.name !== 'Dashboard Only')) && <TableCell align="right" component="th" scope="row">
                                    {/* {row?.score_accepted ? <Button disabled variant="contained">Accepted</Button> : <Button variant="outlined" onClick={() => { setAcceptComment(true); setId(row?.id) }}>Accept</Button>} */}

                                    <Box sx={{ width: "100%", pr: 2 }} display="flex" alignItems="center" justifyContent="flex-end">
                                        {row?.score_reviewed && <Button disabled variant="contained" sx={{ minWidth: "70px", maxWidth: "70px", fontSize: "12px" }}>Reviewed</Button>}
                                        {(!row?.score_reviewed && loggedUserData?.staff?.staffType !== 10 && loggedUserData?.userLevel?.name !== 'Dashboard Only') && <Button disabled={loggedUserData?.userLevel?.name === 'Dashboard Only'} variant="outlined" sx={{ mx: 1, minWidth: "70px", maxWidth: "70px", fontSize: "12px" }} onClick={() => { setAcceptReview(true); setId(row?.score_id) }}>Review</Button>}

                                    </Box>

                                </TableCell>}
                            </TableRow>)
                        }
                    </TableBody>

                </Table>
                {comment?.length === 0 && <Box sx={{ p: 2, width: "100%", minHeight: "150px" }} display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
                    <Comment sx={{ fontSize: "60px" }} /><br />
                    <Typography variant="body2" sx={{ my: 1 }}>
                        No Comments found for the data range selected.
                    </Typography>
                </Box>}
            </TableContainer>
            <AcceptComment ID={id} setID={setId} fn={getComments} />
            <AcceptOne2One ID={id} setID={setId} fn={getComments} />


        </>
    )
}