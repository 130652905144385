import React, { useState } from 'react';
// REACT ROUTER IMPORTS
import { Link, useSearchParams, useLocation } from 'react-router-dom';
// REDUX IMPORTS
import { useSelector } from 'react-redux';
// MATERIAL IMPORTS
import {
    Box, Button,
    Checkbox,
    IconButton,
    Dialog, DialogContent, DialogTitle, Divider,
    FormControl, FormControlLabel,
    Grid,
    MenuItem, Paper,
    Stack,
    TextField, Typography,
} from '@material-ui/core';
import {
    ArrowDownward,
    Close,
    FilterAltOutlined, Flag,
    Group,
    Settings, Star, Tune
} from '@material-ui/icons';
// import { useTheme } from '@material-ui/styles';
// NPM IMPORTS
import moment from 'moment';
import { useFormik } from 'formik';
import Chart from 'react-apexcharts';
import { useResizeDetector } from 'react-resize-detector';
// COMPONENT IMPORTS
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from "../../components/Page";
import { PATH_EMP } from '../../routes/paths';
import ScoreSummary from './dashboard/scorecard';
import CommentSummary from './dashboard/comments';
import ActivitySummary from './dashboard/activity';
import EmpGoalSummary from './dashboard/goals';
import EmpTaskSummary from "./dashboard/tasksummary";
import EmpDatePicker from "./datepicker";
import BoxHeading from './dashboard/components_emp/box_heading';
// CONTEXT IMPORTS
import { EmpContext } from '../../contexts/employeecontext';
import { SettingsContext } from '../../contexts/SettingsContext';

// import images ---

// import images ---
import averageEvaluatorTimeImage from '../../assets/Dashboard/Evaluator_Dashboard/averageEvaluatorTimeImage.png'
import averageScoreImage from '../../assets/Dashboard/Evaluator_Dashboard/averageScoreImage.png'
import evaluationCompletedImage from '../../assets/Dashboard/Evaluator_Dashboard/evaluationCompletedImage.png'
import percentOfEvaluationImage from '../../assets/Dashboard/Evaluator_Dashboard/percentOfEvaluationImage.png';
import groupIcon from '../../assets/emp_dashboard_icons/groupIcon.svg';
import teamIcon from '../../assets/emp_dashboard_icons/teamIcon.svg';
import flagIcon from '../../assets/emp_dashboard_icons/flagIcon.svg';
import kudosIcon from '../../assets/emp_dashboard_icons/kudosIcon.svg';
import bg from '../../assets/emp_dashboard_icons/bg.png';
import CoachingSummary from './dashboard/coaching';


export default function EmpDashboard() {
    // const theme = useTheme();
    // REACT ROUTER STATES
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname } = useLocation();
    // NPM STATES
    const { width, ref } = useResizeDetector();
    // REDUX STATES
    const summary = useSelector(state => state.empSummary);
    // CONTEXT STATES
    const { strtDate, endDate, filterby, setFilterBy, IdEmp } = React.useContext(EmpContext);
    const { setColor, themeMode } = React.useContext(SettingsContext);
    // REACT STATES
    const [value, setValue] = React.useState([strtDate, endDate]);
    const [tscore, setTscore] = useState([]);
    const [myscore, setMyscore] = useState([]);
    const [xLabel, setXLabel] = useState([]);
    const [setting, setSeting] = useState(false);
    const [performance, setPerformance] = useState(true);
    const [comment, setComment] = useState(true);
    const [score, setScore] = useState(true);
    const [activity, setActivity] = useState(true);
    const [task, setTask] = useState(true);
    const [goal, setGoal] = useState(true);
    const [openfilter, setopenfilter] = useState(false)


    // FORMIK METHOD
    const formik = useFormik({
        initialValues: {
            performanceTab: performance, commentTab: comment, scoreTab: score, activityTab: activity,
            taskTab: task, goalTab: goal
        },
        onSubmit: values => {
            setPerformance(values.performanceTab);
            setComment(values.commentTab);
            setScore(values.scoreTab);
            setActivity(values.activityTab);
            setTask(values.taskTab);
            setGoal(values.goalTab);
            setSeting(false)
        }
    })

    React.useEffect(() => {
        async function getdata() {

            if (summary.staff_graph_data || summary.team_graph_data) {
                const arr1 = summary?.staff_graph_data.map(obj => { if (obj?.created_at__week || obj?.created_at__month) { return ((obj.total_score / (obj.total_sum - obj.na_sum)) * 100)?.toFixed(2) } return '0' });
                const arr2 = summary?.team_graph_data.map(obj => { if (obj?.created_at__week || obj?.created_at__month) { return ((obj.total_score / (obj.total_sum - obj.na_sum)) * 100)?.toFixed(2) } return '0' });
                setTscore([]); setMyscore([]);

                // jshint sub:true
                if ((summary?.staff_graph_data?.length > 0 && summary?.staff_graph_data?.some(obj => obj?.created_at__month)) || summary?.team_graph_data?.length > 0 && summary?.team_graph_data?.some(obj => obj?.created_at__month)) {
                    const a = []
                    const tempArr = [...new Set(summary?.staff_graph_data.map(el => { if (el) { return a?.push(`${moment(el?.created_at__month, 'MM').format('MMMM')}`) } return null }))];
                    const b = []
                    const tempArr1 = [...new Set(summary?.team_graph_data.map(el => { if (el) { return b?.push(`${moment(el?.created_at__month, 'MM').format('MMMM')}`) } return null }))]
                    if (a?.length > b?.length) {
                        setXLabel(a)
                        // setXLabel([...new Set(summary?.staff_graph_data.map(el => `${moment(el?.created_at__month, 'MM').format('MMMM')}`))]);
                    }
                    if (b?.length > a?.length) {
                        setXLabel(b)
                        // setXLabel([...new Set(summary?.team_graph_data.map(el => `${moment(el?.created_at__month, 'MM').format('MMMM')}`))]);
                    }
                    if (b?.length === a?.length) {
                        setXLabel(a)
                        // setXLabel([...new Set(summary?.staff_graph_data.map(el => `${moment(el?.created_at__month, 'MM').format('MMMM')}`))]);
                    }

                    setTscore(arr2); setMyscore(arr1);
                }

                if ((summary?.staff_graph_data?.length > 0 && summary?.staff_graph_data?.some(obj => obj?.created_at__week)) || summary?.team_graph_data?.length > 0 && summary?.team_graph_data?.some(obj => obj?.created_at__week)) {
                    const a = []
                    const tempArr = [...new Set(summary?.staff_graph_data.map(el => { if (el) { return a?.push(`Week ${el?.created_at__week}`) } return null }))];
                    const b = []
                    const tempArr1 = [...new Set(summary?.team_graph_data.map(el => { if (el) { return b?.push(`Week ${el?.created_at__week}`) } return null }))];


                    if (a?.length > b?.length) {
                        setXLabel(a);
                    }
                    if (b?.length > a?.length) {
                        setXLabel(b);

                    }
                    if (b?.length === a?.length) {
                        setXLabel(a);
                    }


                    // setXLabel([...new Set(summary?.staff_graph_data.map(el => `Week ${el?.created_at__week}`))]);
                    setTscore(arr2); setMyscore(arr1);
                }


            }
        }
        if (summary) {
            getdata();
        }
    }, [summary])

    const option = {

        series: [{
            name: 'Team Overall Score',
            data: tscore?.length > 0 ? tscore : []
        }, {
            name: 'My Overall Score',
            data: myscore?.length > 0 ? myscore : []
        },],
        options: {
            colors: ['#F27534', '#788BD9'],
            chart: {
                type: 'line',
                dropShadow: {
                    enabled: true,
                    top: -5,
                    left: 0,
                    blur: 2,
                    opacity: 0.2,
                    color: ['#F27534', '#788BD9']
                },
                toolbar: {
                    show: false
                },
            },

            dataLabels: {
                enabled: false,
            },
            grid: {

                show: true, borderColor: '#c7f3fc',
            },

            stroke: {
                curve: 'straight',
                width: 3,
            },
            xaxis: {

                categories: xLabel?.length > 0 ? xLabel : [],
                labels: {
                    style: {
                        colors: themeMode === 'light' ? '#666666' : '#ffffff',

                    },
                }
            },
            yaxis: {
                max: 100,
                tickAmount: 4,
                labels: {
                    show: (tscore?.length > 0 || myscore?.length > 0),
                    formatter: (val) =>
                        val?.toFixed(1),
                    style: {
                        colors: themeMode === 'light' ? '#666666' : '#ffffff',

                    },

                },
                title: {
                    text: 'Quality Score',
                    rotate: -90,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 500,
                        cssClass: 'apexcharts-yaxis-title',
                    },
                },
            },
            fill: {
                opacity: 1
            },
            noData: {
                text: "No data found for the data range selected.",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '18px',
                    fontWeight: "bold",
                    color: themeMode === 'light' ? '#666666' : '#ffffff'
                }
            },
            legend: {
                labels: {
                    colors: themeMode === 'light' ? ['#666666'] : ['#ffffff'],
                    useSeriesColors: false,
                }, showForSingleSeries: true, fontSize: '12px', position: "top", horizontalAlign: "right", height: 40
            },
            markers: {
                size: 10,
                strokeColor: ['#F27534', '#788BD9'],
                strokeWidth: 4,
                strokeOpacity: 0.9,
                // strokeDashArray: 0,
                colors: '#fff',
                fillOpacity: 0.9,
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0
            },
            title: {
                text: 'Quality Score',
                align: 'left',
                // marginTop: '10px',
                offsetX: 10,
                offsetY: 20,
                floating: false,
                style: {
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: '#000000',
                    // color: themeMode === 'light' ? '#666666' : '#ffffff'
                },
            },
        },
    };

    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));



    // console.log(moment(0, "HH").endOf('quarter').format('YYYY-MM-DD').toString());

    return (
        <>
            <Page title="Employee Dashboard | BeyondQA">



                <Box component={Paper} elevation={5} sx={{ mb: 2, mt: loggedUserData?.staff?.staffType !== 10 ? 5 : 0, backgroundColor: theme => theme.palette.background.paper }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignContent="center" alignItems={{ sm: "center", xs: 'flex-start' }} sx={{ backgroundColor: theme => theme.palette.background.paper, px: 2, py: 1, borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                        {/* <Typography variant="h4" sx={{ color: theme => theme.palette.text.primary, textTransform: 'capitalize' }}>
                        {!IdEmp && `Welcome ${searchParams.get("stff_emp_id") ? location.state?.stffName : loggedUserData?.firstName}`}
                        {IdEmp && `Welcome ${IdEmp?.user?.first_name}`}

                    </Typography> */}
                        <Box>
                            {(loggedUserData?.staff?.staffType === 10 && pathname?.split('/')[2] === 'dashboard') && <Typography variant='h5' sx={{ color: theme => theme.palette.text.buttonText, fontWeight: 500, mr: 4, textTransform: 'capitalize' }}>Welcome back, {`${loggedUserData?.firstName}`}</Typography>}

                            <HeaderBreadcrumbs
                                sx={{ p: 0, m: 0, color: '#666666', fontWeight: 600 }}

                                links={[{ name: 'Home', href: PATH_EMP.dashboard }, { name: 'Employee Dashboard' }]}

                            />
                            <Typography variant={{ sm: 'h4', xs: 'caption' }} sx={{ mt: 0, fontWeight: '400', color: '#000000' }}>
                                Employee Dashboard
                            </Typography>



                        </Box>

                        <Stack direction="row" alignItems="center" >
                            <EmpDatePicker />
                            <IconButton size='small' sx={{
                                ml: 1,
                                '&:hover': { backgroundColor: (theme) => theme.palette.background.paper },
                                backgroundColor: (theme) => theme.palette.background.paper, borderRadius: '5px',
                                border: `1px solid #fb5d2e`
                            }} onClick={() => {
                                setopenfilter(!openfilter);
                            }}  >
                                <FilterAltOutlined sx={{
                                    color: '#fb5d2e',
                                    stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px',
                                    '&:hover': {
                                        backgroundColor: (theme) => theme.palette.background.paper,
                                        color: '#fb5d2e'
                                    },
                                    backgroundColor: (theme) => theme.palette.background.paper
                                }} />
                            </IconButton>
                            <IconButton size='small' sx={{
                                mx: 1,
                                '&:hover': { backgroundColor: (theme) => theme.palette.background.paper },
                                backgroundColor: (theme) => theme.palette.background.paper, borderRadius: '5px',
                                border: `1px solid #fb5d2e`
                            }} onClick={() => { setSeting(true) }}><Tune sx={{
                                color: '#fb5d2e',
                                stroke: (theme) => theme.palette.background.paper, strokeWidth: '1px',
                                '&:hover': {
                                    backgroundColor: (theme) => theme.palette.background.paper,
                                    color: '#fb5d2e'
                                },
                                backgroundColor: (theme) => theme.palette.background.paper
                            }} /></IconButton>
                        </Stack>

                    </Stack>
                    <Box sx={{ py: 1, px: { xs: 2, lg: 1, xl: 2 }, height: { lg: '190px', xl: '250px' } }} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems={{ sm: "center", xs: 'flex-start' }} justifyContent="space-around" >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"

                            component={Paper}
                            elevation={5}
                            sx={{
                                flexGrow: 1,
                                borderRadius: { xs: 1, sm: 3 },
                                width: '100%',
                                mx: { lg: 2, xl: 3 },
                                px: 2,
                                my: { sm: 0, xs: 1 },
                                py: { xs: 3, sm: 0 },
                                boxShadow: "0 1px 6px 1px #c8cfc9",
                                // border: '2px solid #E9EBF3',
                                height: { xs: '85%', lg: '58%', xl: '65%' },
                                // borderRadius: 2,
                                // backgroundColor: "#EFF1F7"


                                //  height: '385px', width: '385px'
                            }}>

                            <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', height: '60px', width: '60px', backgroundColor: '#788BD9' }} >
                                <img src={groupIcon} alt='new' />
                            </Box>

                            <Box sx={{
                                ml: 4,
                                backgroundImage: `url(${bg})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: 'contain'
                            }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "normal", fontSize: { lg: '15px', xl: '14px' },
                                        color: '#95a5a6'
                                    }} >
                                    Team OverAll Score
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { lg: '20px', xl: '35px' },
                                        color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600
                                    }}>
                                    {`${(summary?.team_overall_score?.total_sum !== 0 && !Number.isNaN((summary?.team_overall_score?.total_score / (summary?.team_overall_score?.total_sum - summary?.team_overall_score?.na_sum)) * 100)) ? ((summary?.team_overall_score?.total_score / (summary?.team_overall_score?.total_sum - summary?.team_overall_score?.na_sum)) * 100)?.toFixed(2) : 0}%`}

                                </Typography>
                            </Box>

                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"

                            component={Paper}
                            elevation={5}
                            sx={{
                                flexGrow: 1,
                                borderRadius: { xs: 1, sm: 3 },
                                width: '100%',
                                mx: { lg: 2, xl: 3 },
                                px: 2,
                                my: { sm: 0, xs: 1 },
                                py: { xs: 3, sm: 0 },
                                boxShadow: "0 1px 6px 1px #c8cfc9",
                                // border: '2px solid #E9EBF3',
                                height: { xs: '85%', lg: '58%', xl: '65%' },
                                // borderRadius: 2,
                                // backgroundColor: "#EFF1F7"


                                //  height: '385px', width: '385px'
                            }}>
                            <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', height: '60px', width: '60px', backgroundColor: '#fa808a' }} >
                                <img src={teamIcon} alt='new' />
                            </Box>
                            <Box sx={{ ml: 4 }}>
                                <Typography
                                    sx={{
                                        fontWeight: "normal", fontSize: { lg: '15px', xl: '14px' },
                                        color: '#95a5a6'
                                    }} >
                                    My OverAll Score
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { lg: '20px', xl: '35px' },
                                        color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600
                                    }}>
                                    {`${(summary?.overall_score?.total_sum !== 0 && !Number.isNaN((summary?.overall_score?.total_score / (summary?.overall_score?.total_sum - summary?.overall_score?.na_sum)) * 100)) ? ((summary?.overall_score?.total_score / (summary?.overall_score?.total_sum - summary?.overall_score?.na_sum)) * 100)?.toFixed(2) : 0}%`}
                                </Typography>
                            </Box>

                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"

                            component={Paper}
                            elevation={5}
                            sx={{
                                flexGrow: 1,
                                borderRadius: { xs: 1, sm: 3 },
                                width: '100%',
                                mx: { lg: 2, xl: 3 },
                                px: 2,
                                my: { sm: 0, xs: 1 },
                                py: { xs: 3, sm: 0 },
                                boxShadow: "0 1px 6px 1px #c8cfc9",
                                // border: '2px solid #E9EBF3',
                                height: { xs: '85%', lg: '58%', xl: '65%' },
                                // borderRadius: 2,
                                // backgroundColor: "#EFF1F7"


                                //  height: '385px', width: '385px'
                            }}>
                            <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', height: '60px', width: '60px', backgroundColor: '#7dd1b3' }} >
                                <img src={kudosIcon} alt='new' />
                            </Box>

                            <Box sx={{ ml: 4 }}>
                                <Typography
                                    sx={{
                                        fontWeight: "normal", fontSize: { lg: '15px', xl: '14px' },
                                        color: '#95a5a6'
                                    }} >
                                    Kudos
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { lg: '20px', xl: '35px' },
                                        color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600
                                    }}>
                                    {summary?.kudos || 0}
                                </Typography>
                            </Box>

                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"

                            component={Paper}
                            elevation={5}
                            sx={{
                                flexGrow: 1,
                                borderRadius: { xs: 1, sm: 3 },
                                width: '100%',
                                mx: { lg: 2, xl: 3 },
                                px: 2,
                                my: { sm: 0, xs: 1 },
                                py: { xs: 3, sm: 0 },
                                boxShadow: "0 1px 6px 1px #c8cfc9",
                                // border: '2px solid #E9EBF3',
                                height: { xs: '85%', lg: '58%', xl: '65%' },
                                // borderRadius: 2,
                                // backgroundColor: "#EFF1F7"


                                //  height: '385px', width: '385px'
                            }}>
                            <Box sx={{ ml: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '12px', height: '60px', width: '60px', backgroundColor: '#ffab73' }} >
                                <img src={flagIcon} alt='new' />
                            </Box>

                            <Box sx={{ ml: 4 }}>

                                <Typography
                                    sx={{
                                        width: '40%',
                                        fontWeight: "normal", fontSize: { lg: '15px', xl: '14px' },
                                        color: '#95a5a6'
                                    }} >
                                    Flags
                                </Typography>
                                <Typography
                                    sx={{

                                        fontSize: { lg: '20px', xl: '35px' },
                                        color: (theme) => theme.palette.text.sidebarSecondaryText, fontWeight: 600
                                    }}>
                                    {summary?.flag || 0}
                                </Typography>
                            </Box>

                        </Box>
                    </Box>
                </Box>

                <Box component={Paper} elevation={5} sx={{ my: 2, py: 1, backgroundColor: theme => theme.palette.background.paper }}>

                    <Box display="flex" justifyContent="center" className='chart-wrapper' ref={ref} sx={{ height: "88%", width: "100%", py: 2, px: 2 }}>
                        <Chart options={option.options} series={option.series} type="line" width={width} height={450} />
                    </Box>

                </Box>


                {/* ----------------------------------summary------------------------   */}

                <Box component={Paper} elevation={5} sx={{ p: 2, borderRadius: '5px', my: 4, display: score ? "block" : "none" }}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 2, px: 2 }} >
                        <Typography variant='h6'>Scorecards</Typography>
                        <Link to={searchParams.get('stff_emp_id') ? `/employee/scores?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : "/employee/scores"} /* style={{ color: theme === 'light' ? themeColor?.main : themeColor?.dark }} */ >
                            All Scorecards
                        </Link>
                    </Stack>
                    <ScoreSummary theme={themeMode} themeColor={setColor} />
                </Box>

                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='center' justifyContent='space-between' sx={{ my: 3 }}>
                    <Box component={Paper} elevation={5} sx={{ width: { xs: '95%', sm: '49.5%' }, my: { xs: 3, sm: 0 }, p: 2, borderRadius: '5px', display: score ? "block" : "none" }}>
                        <BoxHeading title='Comments' linkTitle='All Comments'
                            link={
                                loggedUserData?.staff?.staffType === 10 ? "/employee/comments" : `/employee/comments?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=${(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? 'False' : 'True'}`
                            }
                        />
                        {/* <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 1, px: 1 }} >
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>Comments</Typography>
                            {loggedUserData?.staff?.staffType === 10 && <Link to="/employee/comments" >

                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>All Comments</Typography>

                            </Link>}
                            {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={`/employee/comments?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=False`} >

                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>All Comments</Typography>

                            </Link>}
                            {!loggedUserData?.staff && <Link to={`/employee/comments?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=True`} >

                                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>All Comments</Typography>

                            </Link>}
                        </Stack> */}
                        <CommentSummary theme={themeMode} themeColor={setColor} />
                    </Box>
                    <Box component={Paper} elevation={5} sx={{ width: { xs: '95%', sm: '49.5%' }, p: 2, my: { xs: 3, sm: 0 }, borderRadius: '5px', display: score ? "block" : "none" }}>
                        <BoxHeading title='Coaching Session' linkTitle='View All Coaching Session' link={PATH_EMP.coaching.viewCoaching} />
                        {/* <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 2, px: 2 }} >
                            <Typography variant='h6'>Coaching Session</Typography>
                            <Link to={PATH_EMP.coaching.viewCoaching}>
                                View All Coaching Session
                            </Link>

                        </Stack> */}
                        <CoachingSummary />
                    </Box>
                </Stack>


                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='center' justifyContent='space-between' sx={{ my: 3 }}>
                    <Box component={Paper} elevation={5} sx={{ width: { xs: '95%', sm: '49.5%' }, my: { xs: 3, sm: 0 }, p: 2, borderRadius: '5px', display: score ? "block" : "none" }}>
                        <BoxHeading title='Latest Activity' linkTitle='View All Activity' link={searchParams.get('stff_emp_id') ? `/employee/activity?stff_emp_id=${searchParams.get('stff_emp_id')}&global_dash=True` : "/employee/activity"} />
                        <ActivitySummary theme={themeMode} themeColor={setColor} />
                    </Box>
                    <Box component={Paper} elevation={5} sx={{ width: { xs: '95%', sm: '49.5%' }, p: 2, my: { xs: 3, sm: 0 }, borderRadius: '5px', display: score ? "block" : "none" }}>
                        <BoxHeading title='Tasks' linkTitle='View All Tasks' link={loggedUserData?.staff?.staffType === 10 ? "/employee/tasks" : `/employee/tasks?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=${(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? 'False' : 'True'}`} />


                        <EmpTaskSummary theme={themeMode} themeColor={setColor} />
                    </Box>
                </Stack>
                <Box component={Paper} elevation={5} sx={{ width: { xs: '95%', sm: '49.5%' }, p: 2, my: { xs: 3, sm: 0 }, borderRadius: '5px', display: score ? "block" : "none" }}>
                    <BoxHeading title='View Goals' linkTitle='View All Goals' link={loggedUserData?.staff?.staffType === 10 ? "/employee/goals" : `/employee/goals?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=${(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) ? 'False' : 'True'}`} />
                    <EmpGoalSummary theme={themeMode} themeColor={setColor} />
                </Box>


                <Dialog open={setting} fullWidth maxWidth="xs">
                    <DialogTitle sx={{ borderBottom: theme => `2px solid ${theme.palette.text.primaryBolderBorder}` }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 1, px: 2 }}>
                            <Typography variant='h5' sx={{ p: 0 }}>
                                Setting
                            </Typography>
                            <IconButton onClick={() => {
                                setSeting(false);
                                formik.setFieldValue('performanceTab', performance);
                                formik.setFieldValue('commentTab', comment);
                                formik.setFieldValue('scoreTab', score);
                                formik.setFieldValue('taskTab', task);
                                formik.setFieldValue('activityTab', activity);
                                formik.setFieldValue('goalTab', goal);
                            }}>
                                <Close fontSize='small' />
                            </IconButton>
                        </Stack>
                    </DialogTitle>
                    {/* <Divider /> */}
                    <DialogContent sx={{ px: 2 }}>
                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ height: "280px" }}>
                                <Stack direction="row" justifyContent="space-around" alignItems="center" sx={{ px: 4, height: "120px" }}>

                                    <Box display="flex" justifyContent="flex-start" sx={{ width: "33.33%" }}>
                                        <FormControlLabel name='performanceTab' checked={formik.values.performanceTab} onChange={() => { formik.setFieldValue('performanceTab', !formik.values.performanceTab); }} label="Performance" control={<Checkbox size="small" />} />

                                    </Box>
                                    <Box display="flex" justifyContent="center" sx={{ width: "33.33%" }}>
                                        <FormControlLabel name='scoreTab' checked={formik.values.scoreTab} onChange={() => { formik.setFieldValue('scoreTab', !formik.values.scoreTab) }} label="Score" control={<Checkbox size="small" />} />

                                    </Box>
                                    <Box display="flex" justifyContent="flex-end" sx={{ width: "33.33%" }}>
                                        <FormControlLabel checked={formik.values.commentTab} onChange={() => { formik.setFieldValue('commentTab', !formik.values.commentTab) }} label="Comments" name='commentTab' control={<Checkbox size="small" />} />

                                    </Box>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 4, height: "120px" }}>

                                    <Box display="flex" justifyContent="flex-start" sx={{ width: "33.33%" }}>
                                        <FormControlLabel checked={formik.values.taskTab} onChange={() => { formik.setFieldValue("taskTab", !formik.values.taskTab) }} label="Task" name='taskTab' control={<Checkbox size="small" />} />

                                    </Box>
                                    <Box display="flex" justifyContent="center" sx={{ width: "33.33%" }}>
                                        <FormControlLabel checked={formik.values.activityTab} onChange={() => { formik.setFieldValue('activityTab', !formik.values.activityTab) }} label="Activity" name='activityTab' control={<Checkbox size="small" />} />

                                    </Box>
                                    <Box display="flex" justifyContent="flex-end" sx={{ width: "33.33%" }}>
                                        <FormControlLabel checked={formik.values.goalTab} onChange={() => { formik.setFieldValue('goalTab', !formik.values.goalTab) }} label="Goals" name='goalTab' control={<Checkbox size="small" />} />

                                    </Box>

                                </Stack>
                                <Stack direction="row" justifyContent="flex-end" sx={{ px: 3 }}>
                                    <Button size='small' sx={{ boxShadow: 'none', border: '1px solid #dddde7', fontSize: '12px', borderRadius: '7px', '&:hover': { color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper }, color: theme => theme.palette.text.buttonText, backgroundColor: theme => theme.palette.background.paper }} variant="contained" type="submit">Confirm</Button>
                                </Stack>

                            </Box>
                        </form>

                    </DialogContent>
                </Dialog>
                <Dialog open={openfilter} fullWidth maxWidth="xs">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ px: 2, py: 1 }}>
                        <DialogTitle>
                            <Typography variant="h6">Filter</Typography>
                        </DialogTitle>
                        <IconButton onClick={() => { setopenfilter(false); }}><Close /></IconButton>
                    </Stack>
                    <Divider />
                    <DialogContent sx={{ p: 2, minHeight: "120px" }}>
                        <Box sx={{ width: "100%" }}>
                            <FormControl fullWidth>

                                <TextField
                                    id="filter_by"
                                    multiple={false}
                                    select
                                    label="Filter By"
                                    variant="outlined"
                                    sx={{ mt: 1 }}
                                    name="filter_by"
                                    value={filterby}

                                >
                                    <MenuItem sx={{ color: 'text.primary' }} onClick={() => { setFilterBy("created_at") }} value="created_at">Score Date</MenuItem>
                                    <MenuItem sx={{ color: 'text.primary' }} onClick={() => { setFilterBy("event_date") }} value="event_date">Event Date</MenuItem>
                                    <MenuItem sx={{ color: 'text.primary' }} onClick={() => { setFilterBy("updated_at") }} value="updated_at">Edited Date</MenuItem>
                                </TextField>

                            </FormControl>
                        </Box>
                    </DialogContent>
                </Dialog>
            </Page >
        </>
    )
}




