/* eslint-disable import/no-unresolved */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// import { Box } from '@material-ui/core';
// layouts
// import MainLayout from '../layouts/main';
// import DashboardLayout from '../layouts/dashboard';
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import EmployeeDashboardLayout from '../layouts/dashboard/employee_dashboard';
import SupervisorDashboardLayout from '../layouts/dashboard/supervisor_dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
//
// import PrivateRoute from './pvtRoute';
// import CalibrationListTable from '../pages/components-overview/material-ui/table/calibration/calibration list';
// import CalibrationListBuilder from '../pages/components-overview/material-ui/table/calibration/calibration_list_builder';
// import CalibrateTable from '../pages/components-overview/material-ui/table/calibration/calibrate';
// import CalibrationReportTable from '../pages/components-overview/material-ui/table/calibration/calibration_report';

import { AnalyticProviders } from '../contexts/AnalyticContext';
// import { ScoreCardsProvider } from '../contexts/ScoreCardsContext';
import EmpProviders from '../contexts/employeecontext';
import EvalDashboardProviders from '../contexts/EvaluatorDashboardContext'
import StaffProviders from '../contexts/staffContext';
import CalibrationProviders from '../contexts/calibrationContext';
import AIContextProviders from '../contexts/AIContext';

import EmpDashboard from '../pages/employee/employeeDashboard';
import EmpGoals from '../pages/employee/goals/index';
import EmpTasks from '../pages/employee/task';
import EmpActivity from '../pages/employee/activity';
import EmpComment from '../pages/employee/comments';
// import StaffProviders from '../contexts/staffContext';
// import { AnalyticProviders } from '../contexts/AnalyticContext';
import EmpScore from '../pages/employee/scores';
// import SuperVisorDashboard from '../pages/supervisor/supervisordashboard';
import ScoreGroupTable from '../pages/components-overview/material-ui/table/score/scretble';
import SelectEmployeeTable from '../pages/components-overview/material-ui/table/score/selectemptable';
import SelectScoreCardTable from '../pages/components-overview/material-ui/table/score/selectscorecard';
// import { ScoreCardsProvider } from '../contexts/ScoreCardsContext';
import ScoreDraftTable from '../pages/components-overview/material-ui/table/score/scredraft';
import AdminDashboard from '../pages/admin_dashboard/index';
import ListScore from '../pages/components-overview/material-ui/table/score/listscore';
import MessageHomeTable from '../pages/components-overview/material-ui/table/message/index';
import MessageInboxTable from '../pages/components-overview/material-ui/table/message/inbox';
// import CalibrationListTable from '../pages/components-overview/material-ui/table/calibration/calibration list';
// import CalibrationListBuilder from '../pages/components-overview/material-ui/table/calibration/calibration_list_builder';
// import CalibrateTable from '../pages/components-overview/material-ui/table/calibration/calibrate';
// import CalibrationReportTable from '../pages/components-overview/material-ui/table/calibration/calibration_report';
// import CalibrationProviders from '../contexts/calibrationContext';
// import CalibratePerformance from "../pages/calibration components/calibratePerformance";
// import EditCalibratePerformance from "../pages/calibration components/edit_performance_calibrate";
// import EditCalibrate from "../pages/calibration components/edit_calibration";
// import ViewCalibrateScore from "../pages/dashboard/viewCalibrateScore";
import AcceptanceGroupTable from "../pages/components-overview/material-ui/table/analytics/groups/6.7 acceptance_by_group";
import AcceptanceResultTable from "../pages/dashboard/analytics/staff/1.11 Result Acceptance By Agent/1.11 acceptance_by_result";
// import StaffTrendReports from "../pages/dashboard/analytics/staff/1.1 Overall Staff Trend/StaffTrendReport";
// import OverallStaffTrendByIndivitual from "../pages/dashboard/analytics/staff/1.4 Overall Trend By Individual/OverallStaffTrendByIndivitual";
// import QuestionTrend from "../pages/dashboard/analytics/scorecards/4.4 Question Trend/QuestionTrend";
// import EvaluationPerScorecardWrapper from "../pages/dashboard/analytics/evaluation/evaluation_per_scorecard";
// import EvaluationPerScorecardEvaluator from "../pages/dashboard/analytics/evaluation/EvaluationPerCardByEvaluator/EvaluationPerSocreCardEvaluator"
// import SectionTrendReport from "../pages/dashboard/analytics/scorecards/4.5 Section Trend/ScorecardsSectionTrend";
// import CausesByScorecard from "../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesByScorecard";
// import CausesBySections from "../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesBySections";
// import CausesByQuestion from "../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesByQuestion";
// import CausesByIndQuestion from "../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesByIndQuestion";
// import CausesReportResultDetails from "../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesReportResultDetails";
// import AcceptanceTeamTable from "../pages/components-overview/material-ui/table/analytics/acceptance_by_team";
// import AcceptanceAgentTable from "../pages/components-overview/material-ui/table/analytics/staff/acceptance_by_agent";
import CustomDashboardTest from "../pages/components-overview/material-ui/table/analytics/customDashboard";
// import EvaluatorDashboardLayout from 'src/layouts/dashboard/evaluator_dashboard/EvaluatorDashboardLayout';
// import SectionTrendIndividualWrapper from "../pages/dashboard/analytics/staff/1.5 Section Trend by Individual/section_trend_individual";
// import QuestionTrendIndividual from '../pages/dashboard/analytics/staff/1.6 Question Trend By Individual/QuestionTrendIndividual';
// import SetionTrendDetailsTable from '../pages/dashboard/analytics/scorecards/4.5 Section Trend/SetionTrendDetailsTable';
// import QuestionByStaff from '../pages/dashboard/analytics/staff/1.2 Question By Staff/QuestionByStaff';
// import QuestionByStaffDetails from '../pages/dashboard/analytics/staff/1.2 Question By Staff/QuestionByStaffDetails';
// import QuestionIndividualResultDetail from '../pages/dashboard/analytics/staff/1.6 Question Trend By Individual/QuestionIndividualResultDetail';
// import CausesTrend from '../pages/dashboard/analytics/scorecards/4.8/CausesTrend';
// import OverAllTeamsTrends from '../pages/dashboard/analytics/teams/5.1/OverAllTeamsTrends';
// import OverAllTeamsTrendsDetails from '../pages/dashboard/analytics/teams/5.1/OverAllTeamsTrendsDetails';
// import AnswersByStaff from '../pages/dashboard/analytics/staff/1.3 Answers By Staff/AnswersByStaff';
// import AnswersByStaffDetails from '../pages/dashboard/analytics/staff/1.3 Answers By Staff/AnswersByStaffDetails';
// import AnswersByStaffDistribution from '../pages/dashboard/analytics/staff/1.3 Answers By Staff/AnswerByStaffDistribution';
// import SupervisorBySection from '../pages/dashboard/analytics/supervisor/2.1 Supervisor By Section/SupervisorBySection';
// import TeamSupervisor from '../pages/dashboard/analytics/teams/5.1/TeamSupervisor';
// import StaffTrendsBySupervisor from '../pages/dashboard/analytics/teams/5.1/StaffTrendsBySupervisor';
// import StaffTrendsByStaffResult from '../pages/dashboard/analytics/teams/5.1/StaffTrendsByStaffResult';
// import PointsOfFailureByTeam from '../pages/dashboard/analytics/teams/5.2/PointsOfFailureByTeam';
// import PointsOfFailureByTeamByScorecard from '../pages/dashboard/analytics/teams/5.2/PointsOfFailureByTeamByScorecard';
// import PointsOfFailureReportResultDetails from '../pages/dashboard/analytics/teams/5.2/PointsOfFailureReportResultDetails';
// import StaffBySection from '../pages/dashboard/analytics/supervisor/2.1 Supervisor By Section/StaffBySection';
// import SupervisorSectionDetails from '../pages/dashboard/analytics/supervisor/2.1 Supervisor By Section/SupervisorSectionDetails';
// import CausesByStaff from "../pages/dashboard/analytics/staff/1.10/causes_by_staff";
// import EvaluationPerPeriod from "../pages/dashboard/analytics/evaluation/evaluationperperiod/evaluation_per_period";
// import SupervisorByQuestion from '../pages/dashboard/analytics/supervisor/2.3/SupervisorByQuestion';
// import SupervisorByQuestionDetails from '../pages/dashboard/analytics/supervisor/2.3/SupervisorByQuestionDetails';
// import EvaluationPerScorecardByStaff from "../pages/dashboard/analytics/evaluation/EvaluationPerScorecardByStaff/Evaluations_Per_Scorecard_By_Staff";
// import ResultAcceptenceBySupervisor from '../pages/dashboard/analytics/supervisor/2.4 Result Acceptance By Supervisor/ResultAceptenceBySupervisor/ResultAcceptenceBySupervisor';
// import EvaluationReviewsBySupervisor from "../pages/dashboard/analytics/evaluation/ReviewBySupervisor3_7/reviewbysupervisor";
// import BillingDashboard from "../pages/dashboard/billing/billingdashboard";
// import EventSubTypeTrends from '../pages/dashboard/analytics/scorecards/4.2 Event Type Trend/EventSubTypeTrend'
// import CalibrationTrend from '../pages/dashboard/analytics/groups/6.6 Calibrations Trend/CalibrationTrend';
// import PerformanceAgainstEventSubType from '../pages/dashboard/analytics/scorecards/4.3 Performance Against Target/PerformanceEventSubType';
// import ScorecardCategoriesDetails from '../pages/dashboard/analytics/groups/6.2 Scorecard Categories/ScorecardCategoriesDetails';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense fallback={
      <LoadingScreen
        sx={{
          ...(!isDashboard && {
            top: 0,
            left: 0,
            width: 1,
            zIndex: 9999,
            position: 'fixed'
          })
        }}
      />
    }>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  // const tokenData = useParams();

  // console.log('token..........', tokenData);

  return useRoutes([
    // Default page when visit website.
    // { path: 'test', element: <Test2 /> },
    { path: 'verification/:token/:email', element: <ResetLink /> },
    { path: 'verification/:token/:email/:staffType', element: <ResetLink /> },
    // { path: 'test2', element: <AdminDashboard /> },

    { path: '/userTypes', element: <UserTypes /> },
    {
      path: '/test/edit_calibrate_list',
      element: (
        <StaffProviders>
          <EmpProviders type="staff">
            <CalibrationProviders>
              {' '}
              <EditCalibrate />
            </CalibrationProviders>
          </EmpProviders>
        </StaffProviders>
      )
    },
    {
      path: '/test/report',
      element: (
        <StaffProviders>
          <EmpProviders type="staff">
            <CalibrationProviders>
              {' '}
              <CalibrationReportTable />
            </CalibrationProviders>
          </EmpProviders>
        </StaffProviders>
      )
    },

    // {
    //   path: 'test',
    //   element: (<DashboardLayout />),
    //   children: [
    //     {
    //       path: '/',
    //       element: <SelectGroup />
    //     },
    //   ]
    // },

    // { path: '/editScoreCard', element: <EditScoreCard /> },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'must-change-password', element: <MustChangePassword /> },
        { path: 'reset-link', element: <ResetLink /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        // <RoleBasedGuard>
        <AuthGuard>
          <StaffProviders>
            <AnalyticProviders>
              <EmpProviders type="staff">
                <EvalDashboardProviders>
                  <CalibrationProviders>
                    <AIContextProviders>
                      <DashboardLayout />
                    </AIContextProviders>
                  </CalibrationProviders>
                </EvalDashboardProviders>
              </EmpProviders>
            </AnalyticProviders>
          </StaffProviders>
        </AuthGuard>
        // </RoleBasedGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: 'dashboard', element: <AdminDashboard /> },
        {
          path: 'evaluator_dashboard', element: <EvaluatorDashboardLayout />
        },
        { path: 'admin', element: <MainDashboard /> },
        { path: 'userProfile', element: <UserProfile /> },
        { path: 'companySettings', element: <CompanySettings /> },
        {
          path: 'staff',
          children: [
            {
              path: '/',
              element: (
                <AuthGuard>
                  <AdminStaff />
                </AuthGuard>
              )
            },
            { path: 'uploadstaff', element: <UploadStaff /> }
          ]
        },
        {
          path: 'calibration',
          children: [
            // { path: '/', element: <ComingSoon title="Calibration" /> },
            // { path: '/', element: <CalibrationListTable /> },
            { path: '/', element: <CalibrationDashboard /> },

            { path: '/list_builder', element: <CalibrationListBuilder /> },
            { path: '/calibrate', element: <CalibrateTable /> },
            { path: '/calibrate_report', element: <CalibrationReportTable /> },
            { path: '/calibrate_performance', element: <CalibratePerformance /> },
            { path: '/edit_calibrate_performance', element: <EditCalibratePerformance /> },
            { path: '/edit_calibrate_list', element: <EditCalibrate /> },
            { path: '/view_calibrate_performance', element: <ViewCalibrateScore /> },

            {
              path: 'selectDraft',
              element: <ScoreDraftTable />
            }
          ]
        },
        {
          path: 'billing',
          children: [
            { path: '/', element: <BillingDashboard /> },

          ]
        },
        {
          path: 'scorecards',
          children: [
            { path: '/', element: <ScoreCards /> },
            { path: 'editScoreCard', element: <EditScoreCard /> },
            { path: 'scorecardArchive', element: <ScorecardArchive /> },
            { path: 'scorecardCategory', element: <ScorecardCategory /> },
            { path: 'scorecardSection', element: <ScorecardSection /> },
            { path: 'scorecardComments', element: <ScorecardComments /> },
            { path: 'scorecardCauses', element: <ScorecardCauses /> },
            { path: 'scorecardCustomFields', element: <ScorecardCustomFields /> },
            { path: 'scorecardSettings', element: <ScorecardSettings /> },
            { path: 'editCustomFields', element: <EditCustomFieldsTags /> },
            { path: 'questionDetails', element: <QuestionDetails /> },
            { path: 'scorecardPreview', element: <ScorecardPreview /> },
            { path: 'scorecardEventTypes', element: <ScorecardEventTypes /> },
            { path: 'scorecardNonNumeric', element: <ScorecardNonNumeric /> }
          ]
        },
        {
          path: 'reports',
          children: [
            { path: '/', element: <ReportsDashboard /> },
            { path: 'viewSnapshot', element: <ViewSnapShot /> },
            { path: 'editScore', element: <EditScoreScoreLog /> },
            {
              path: 'reviewPerformance',
              element: <ScorecardPreview3 />
            },
            { path: 'complianceBySection', element: <ComplianceSection /> },
            { path: 'complianceSectionDetails', element: <ComplianceSectionDetails /> },
            { path: 'complianceResults', element: <ComplianceResults /> },
            { path: 'successReportDrillDown', element: <SuccessReportDrillDown /> },
            // { path: 'editScoreCard', element: <EditScoreCard /> }
          ]
        },
        {
          path: 'analytics',
          children: [
            // change
            // { path: '/', element: <AnalyticsDashboard /> },
            { path: '/', element: <CustomDashboardTest /> },
            { path: 'ReportResultDetails', element: <ReportResultDetails /> },
            { path: 'StaffTrendReportResultDetails', element: <StaffTrendReportResultDetails /> },
            { path: 'ReportResultIndDetails', element: <ReportResultIndDetails /> },
            { path: 'QuestionResultDetails', element: <QuestionResultDetails /> },
            { path: 'editScore', element: <EditScoreScoreLog /> },
            { path: 'acceptance_by_team', element: <AcceptanceTeamTable /> },
            { path: 'acceptance_by_result', element: <AcceptanceResultTable /> },
            { path: 'section_details', element: <SectionDetailTable /> },
            { path: 'question_result_individual_details', element: <QuestionIndividualResultDetail /> },
            { path: 'supervisor_staff_by_section', element: <StaffBySection /> },
            { path: 'supervisor_section_details', element: <SupervisorSectionDetails /> },
            {
              path: 'reviewPerformance',
              element: <ScorecardPreview3 />
            },
            {
              path: 'advanced_analytics',
              children: [{ path: '/', element: <AdvanceAnalytics /> },
              { path: 'advance_report_result_details', element: <AdvanceResultDetails /> }]
            },
            {
              path: 'group',
              children: [{ path: 'acceptance_by_group', element: <AcceptanceGroupTable /> },
              { path: 'calibration_trend', element: <CalibrationTrend /> },
              { path: "summary", element: <GroupSummary /> },
              { path: "points_of_failure", element: <PointsOfFailure /> },
              { path: "summary_by_group", element: <SummaryByGroup /> },
              { path: "ReportResultDetails", element: <GroupReportResultDetails /> },
              { path: 'scorecard_categroies', element: <ScoreCardCategories /> },
              { path: 'scorecard_categories_details', element: <ScorecardCategoriesDetails /> },
              { path: 'customer_reference', element: <CustomerReference /> },
              { path: 'compliance_overview', element: <ComplianceOverview /> },
              { path: 'compliance_by_team', element: <ComplianceByTeam /> },
              { path: 'compliance_by_staff', element: <ComplianceByStaff /> },
              { path: 'result_acceptance_by_group', element: <ResultAcceptanceByGroup /> }
              ]
            },
            {
              path: 'staff',
              children: [
                // { path: "staff_trend_report", element: <StaffTrendReports2 /> },
                { path: "acceptance_by_agent", element: <AcceptanceAgentTable /> },
                { path: "staffTrendReport", element: <StaffTrendReports /> },
                { path: "OverallStaffTrendReportInd", element: <OverallStaffTrendByIndivitual /> },
                { path: "QuestionByStaff", element: <QuestionByStaff /> },
                { path: "QuestionByStaffDetails", element: <QuestionByStaffDetails /> },
                { path: "section_trend_individual", element: <SectionTrendIndividualWrapper /> },
                { path: "question_trend_individual", element: <QuestionTrendIndividual /> },
                { path: 'AnswersByStaff', element: <AnswersByStaff /> },
                { path: 'AnswersByStaffDetails', element: <AnswersByStaffDetails /> },
                { path: 'AnswersByStaffDistrubution', element: <AnswersByStaffDistribution /> },
                { path: 'causes_by_staff', element: <CausesByStaff /> },
                { path: 'SectionByEventType', element: <SectionByEventType /> },
                { path: 'SectionByResult', element: <SectionByResult /> },
                { path: 'kudos_by_staff', element: <KudosByStaff /> },
                { path: 'reportResultDetails', element: <StaffResultDetails /> },

              ]
            },
            {
              path: 'supervisor',
              children: [
                { path: "points_of_failure_supervisors", element: <PointOfFailureSupervisors /> },
                { path: "supervisor_by_section", element: <SupervisorBySection /> },
                {
                  path: "supervisor_by_question", element: <SupervisorByQuestion />
                },
                {
                  path: "supervisor_by_question_details", element: <SupervisorByQuestionDetails />
                }
                ,
                {
                  path: "result_acceptence_by_supervisor", element: <ResultAcceptenceBySupervisor />
                },
                {
                  path: "ReportResultDetails", element: <SupervisorReportResultDetails />
                }
              ]
            },
            {
              path: 'evaluation',
              children: [
                // { path: "staff_trend_report", element: <StaffTrendReports2 /> },
                { path: "scoring_patterns", element: <ScoringPatterns /> },
                { path: "evaluation_per_scorecard", element: <EvaluationPerScorecardWrapper /> },
                { path: "evaluation_per_scorecard_evaluator", element: <EvaluationPerScorecardEvaluator /> },
                { path: "evaluation_per_period", element: <EvaluationPerPeriod /> },
                { path: "evaluation_per_scorecard_by_staff", element: <EvaluationPerScorecardByStaff /> },
                { path: "evaluation_review_by_supervisor", element: <EvaluationReviewsBySupervisor /> },
                { path: "evaluation_review_by_evaluator", element: <EvaluationReviewsByEvaluator /> },
                { path: "ReportResultDetails", element: <EvaluationReportResultDetails /> }


              ]
            },
            {
              path: 'scorecards',
              children: [
                { path: 'question_trend', element: <QuestionTrend /> },
                { path: 'overall_trend', element: <OverAllTrends /> },
                { path: 'event_type_trend', element: <EventTypeTrends /> },
                { path: 'event_sub_type_trend', element: <EventSubTypeTrends /> },
                { path: 'OverAllTrendsResultDetail', element: <OverAllTrendsResultDetail /> },
                { path: 'performance_against_target', element: <PerformanceAgainstTarget /> },
                { path: 'performance_against_event_sub_type_trend', element: <PerformanceAgainstEventSubType /> },
                { path: 'score_card_result_details', element: <ScoreCardResultDetailsPage /> },
                { path: 'sectionTrendReport', element: <SectionTrendReport /> },
                { path: 'SetionTrendDetailsTable', element: <SetionTrendDetailsTable /> },
                // 4.6 comment --START---
                { path: 'comments_scorecard', element: <ScoreCardComments /> },
                // 4.6 comment --Ends---
                // 4.8 Causes Trend ---START---
                { path: 'causesTrend', element: <CausesTrend /> },
                { path: 'causesTrendDetails', element: <CausesTrendDetails /> },
                // 4.8 Causes Trend ---STOP---
                // 4.9 Compliance By Question ---START---
                { path: "compliance_by_question", element: <ComplianceByQuestions /> },
                { path: "question_details", element: <QuestionDetailsComplianceByQuestions /> },
                // 4.9 Compliance By Question ---STOP---
                // 4.7 Causes ---START---
                { path: "CausesByScorecard", element: <CausesByScorecard /> },
                { path: "CausesBySections", element: <CausesBySections /> },
                { path: "CausesByQuestion", element: <CausesByQuestion /> },
                { path: "CausesByIndQuestion", element: <CausesByIndQuestion /> },
                { path: "CausesReportResultDetails", element: <CausesReportResultDetails /> },
                // 4.7 Causes ---END---
                // 4.10 Data tags By Event type --
                { path: 'data_tag_by_eventType', element: <DataTagsByEventType /> },
                // 4.11 Data tags By Event type --
                { path: 'customer_reference_by_data_tags', element: <CustomerRefByDataTags /> }
              ]
            },
            {
              path: 'team',
              children: [
                { path: 'overAllTeamsTrends', element: <OverAllTeamsTrends /> },
                { path: 'overAllTeamsTrendsDetails', element: <OverAllTeamsTrendsDetails /> },
                { path: 'teamSupervisor', element: <TeamSupervisor /> },
                { path: 'staffTrendsBySupervisor', element: <StaffTrendsBySupervisor /> },
                { path: 'staffTrendsByStaffResult', element: <StaffTrendsByStaffResult /> },
                // 5.2 Causes ---START---
                { path: 'pointsOfFailureByTeam', element: <PointsOfFailureByTeam /> },
                { path: 'pointsOfFailureByTeamByScorecard', element: <PointsOfFailureByTeamByScorecard /> },
                { path: 'pointsOfFailureReportResultDetails', element: <PointsOfFailureReportResultDetails /> },
                // 5.2 Causes ---END---
              ]
            }
          ]
        },
        {
          path: 'notification',
          children: [
            { path: '/', element: <MessageHomeTable /> },
            { path: 'inbox', element: <MessageInboxTable /> }

            // { path: 'editScoreCard', element: <EditScoreCard /> }
          ]
        },
        {
          path: '/support',
          children: [
            { path: '/', element: <HelpSupportIndex /> },
            { path: '/support_new', element: <HelpSupportCreate /> },
            { path: '/support_view', element: <HelpSupportView /> },

            // { path: 'editScoreCard', element: <EditScoreCard /> }
          ]
        },
        {
          path: '/calls',
          children: [
            { path: '/', element: <CallsIndexTable /> },
            { path: '/call_details', element: <CallDetailsWrapper /> },
          ]
        },
        {
          path: '/analyze',
          children: [
            { path: '/', element: <AnalyzeIndex /> },
            { path: 'word_cloud', element: <Wordcloud /> },
            {
              path: '/event_heat_map', element: <EventHeatMap />
            },
          ]
        },
        {
          path: '/auto_qa_rules',
          children: [
            { path: '/', element: <AutoQARules /> },
            { path: '/auto_form_qa', element: <AutoQARuleForm /> },
            { path: '/form_qa', element: <QAFormRule /> }
          ]
        },
        {
          path: '/coaching',
          children: [
            { path: '/', element: <CoachingDashboardWrapper /> },
            { path: '/add_coaching', element: <AddCoachingSessionWrapper /> },
            { path: '/agent_report_coaching', element: <AgentReport /> },
            { path: '/create_coaching_session', element: <CreateCoachingSessionFormWrapper /> },
            { path: '/view_coaching_session', element: <ViewCoachingSessionWrapper /> },
          ]
        },
        {
          path: '/assignment',
          children: [

            { path: '/', element: <AssignmentIndex /> },
            { path: '/master_config', element: <AssignmentMasterConfig /> },
            { path: '/assignment_details', element: <AssignmentDetailsIndex /> }
          ]
        },
        {
          path: 'score',
          children: [
            {
              path: '/',
              element: <Navigate to="/score/selectgroup" />
            },
            {
              path: 'selectgroup',
              element: <ScoreGroupTable />
            },
            {
              path: 'select_emp',
              element: <SelectEmployeeTable />
            },
            {
              path: 'select_scorecard',
              element: <SelectScoreCardTable />
            },
            {
              path: 'reviewPerformance',
              element: <ScorecardPreview3 />
            },
            {
              path: 'selectDraft',
              element: <ScoreDraftTable />
            }
          ]
        },
        {
          path: 'events',
          children: [
            { path: '/', element: <Moments /> },
            { path: 'archivedEvents', element: <ArchivedMoments /> },
            { path: 'eventsCategory', element: <MomentsCategory /> },
            { path: 'eventBuilder', element: <EventBuilder /> },
          ]
        },
      ]
    },
    {
      path: 'employee',
      element: (
        <AuthGuard>
          <AIContextProviders>
            <StaffProviders>
              {/* <ScoreCardsProvider>
                            <AnalyticProviders>
                        {' '} */}

              <EmpProviders type="emp">

                <EmployeeDashboardLayout />

              </EmpProviders>
              {/* </AnalyticProviders>
                        </ScoreCardsProvider> */}
            </StaffProviders>
          </AIContextProviders>
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/employee/dashboard" /> },
        { path: 'dashboard', element: <EmpDashboard /> },
        // { path: 'score', element: <GeneralEcommerce /> },
        { path: 'goals', element: <EmpGoals /> },
        { path: 'tasks', element: <EmpTasks /> },
        { path: 'activity', element: <EmpActivity /> },
        { path: 'comments', element: <EmpComment /> },
        { path: 'scores', element: <EmpScore /> },
        { path: 'selfscore', element: <ComingSoon title="Self Score" /> },
        { path: 'myscore', element: <ListScore /> },
        {
          path: 'coaching',
          children: [
            {
              path: '/',
              element: <Navigate to="/coaching/view_coaching_session" />
            },
            {
              path: 'view_coaching_session',
              element: <EmpViewCoaching />
            },
            {
              path: 'create_coaching_session',
              element: <CreateCoachingSessionFormWrapper />
            }

          ]
        },
      ]
    },

    {
      path: 'supervisor',
      element: (
        <AuthGuard>
          <AIContextProviders>
            <StaffProviders>
              <EmpProviders type="sup">
                <SupervisorDashboardLayout />
              </EmpProviders>
            </StaffProviders>
          </AIContextProviders>
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/supervisor/dashboard" /> },
        // { path: 'dashboard', element: <GeneralApp /> },
        { path: 'dashboard', element: <SuperVisorDashboard /> }
      ]
    },
    // {
    //   path: 'profile',
    //   children: [
    //     { path: 'userProfile', element: <UserProfile /> }
    //   ]
    // },
    // { path: 'userProfile', element: <UserProfile /> },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
    // { path: 'userProfile', element: <UserProfile /> },
    // { path: 'companySettings', element: <CompanySettings /> },
    //   ],
    // },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const MustChangePassword = Loadable(lazy(() => import('../pages/authentication/MustChangePassword')));
const ResetLink = Loadable(lazy(() => import('../pages/authentication/ResetLink')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const MainDashboard = Loadable(lazy(() => import('../pages/dashboard/MainDashboard')));
// Admin Dashboard
const AdminStaff = Loadable(lazy(() => import('../pages/adminstaff')));
const ScoreCards = Loadable(lazy(() => import('../pages/dashboard/ScoreCardsDashboard')));
const ReportsDashboard = Loadable(lazy(() => import('../pages/dashboard/ReportsDashboard')));
// const AnalyticsDashboard = Loadable(lazy(() => import('../pages/dashboard/AnalyticsDashboard')));
const UserTypes = Loadable(lazy(() => import('../pages/dashboard/UserTypes')));
// Staff Dashboard
const UploadStaff = Loadable(lazy(() => import('../pages/staffComponents/staff/uploadStaff')));
// const StaffProviders = Loadable(lazy(() => import('../contexts/staffContext')));
// const SelectEmployeeTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/score/selectemptable')));
// Employee Dashboard
// const EmployeeDashboardLayout = Loadable(lazy(() => import('../layouts/dashboard/employee_dashboard')));
// const SupervisorDashboardLayout = Loadable(lazy(() => import('../layouts/dashboard/supervisor_dashboard')));
// const LogoOnlyLayout = Loadable(lazy(() => import('../layouts/LogoOnlyLayout')));
// const EmpDashboard = Loadable(lazy(() => import('../pages/employee/employeeDashboard')));
// const EmpGoals = Loadable(lazy(() => import('../pages/employee/goals/index')));
// const EmpTasks = Loadable(lazy(() => import('../pages/employee/task')));
// const EmpActivity = Loadable(lazy(() => import('../pages/employee/activity')));
// const EmpComment = Loadable(lazy(() => import('../pages/employee/comments')));
// const EmpProviders = Loadable(lazy(() => import('../contexts/employeecontext')));
// const EmpScore = Loadable(lazy(() => import('../pages/employee/scores')));
const SuperVisorDashboard = Loadable(lazy(() => import('../pages/supervisor/supervisordashboard')));
// const ScoreGroupTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/score/scretble')));

// const ScoreDraftTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/score/scredraft')));
// const AdminDashboard = Loadable(lazy(() => import('../pages/admin_dashboard/index')));
// const ListScore = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/score/listscore')));
// const MessageHomeTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/message/index')));
// const MessageInboxTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/message/inbox')));

// Scorecard Dashboard
// const {ScoreCardsProvider} = Loadable(lazy(() => import('../contexts/ScoreCardsContext')));
// const SelectScoreCardTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/score/selectscorecard')));
const EditScoreCard = Loadable(lazy(() => import('../pages/dashboard/Scorecards/EditScoreCard')));
const ScorecardArchive = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/ScorecardArchive')));
const ScorecardCategory = Loadable(lazy(() => import('../pages/dashboard/Scorecards/ScorecardCategory')));
const ScorecardSection = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/ScorecardSection')));
const ScorecardComments = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/ScorecardComments')));
const ScorecardCauses = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/ScorecardCauses')));
const ScorecardCustomFields = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/ScorecardCustomFields')));
const ScorecardSettings = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/ScorecardSettings')));
const EditCustomFieldsTags = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/EditCustomFieldsTags')));
const QuestionDetails = Loadable(lazy(() => import('../pages/dashboard/Scorecards/QuestionDetails')));
const ScorecardPreview = Loadable(lazy(() => import('../pages/dashboard/Scorecards/ScorecardPreview')));
// const ScorecardPreview2 = Loadable(lazy(() => import('../pages/dashboard/scoreCardPreview2')));
const ScorecardPreview3 = Loadable(lazy(() => import('../pages/dashboard/optimized_scoreCardPreview2')));
const EditScoreScoreLog = Loadable(lazy(() => import('../pages/dashboard/Reports/EditScoreScoreLog')));
const ViewSnapShot = Loadable(lazy(() => import('../pages/dashboard/Reports/ViewSnapShot')));
const QuestionResultDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.4 Question Trend/QuestionResultDetails')));
const ScorecardEventTypes = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/ScorecardEventTypes')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfileNewUI')));
const CompanySettings = Loadable(lazy(() => import('../pages/dashboard/CompanySettingsnewUi')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ComplianceSection = Loadable(lazy(() => import('../pages/dashboard/Reports/compliance/ComplianceSection')));
const ComplianceSectionDetails = Loadable(lazy(() => import('../pages/dashboard/Reports/compliance/ComplianceSectionDetails')));
const ComplianceResults = Loadable(lazy(() => import('../pages/dashboard/Reports/compliance/ComplianceResults')));
const ScorecardNonNumeric = Loadable(lazy(() => import('../pages/dashboard/Scorecards/More Options/ScorecardNonNumeric')));
const SuccessReportDrillDown = Loadable(lazy(() => import('../pages/dashboard/Reports/success/SuccessReportDrillDown')));

// Calibration Dashboard
const CalibrationDashboard = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/calibration/calibrationDashboard')))
const CalibrationListTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/calibration/calibration list')));
const CalibrationListBuilder = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/calibration/calibration_list_builder')));
const CalibrateTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/calibration/calibrate')));
const CalibrationReportTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/calibration/calibration_report')));
// const CalibrationProviders = Loadable(lazy(() => import('../contexts/calibrationContext')));
const CalibratePerformance = Loadable(lazy(() => import('../pages/calibration components/calibratePerformance')));
const EditCalibratePerformance = Loadable(lazy(() => import('../pages/calibration components/edit_performance_calibrate')));
const EditCalibrate = Loadable(lazy(() => import('../pages/calibration components/edit_calibration')));
const ViewCalibrateScore = Loadable(lazy(() => import('../pages/dashboard/viewCalibrateScore')));

// Analytic Dashboard - Staff
const StaffTrendReports = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.1 Overall Staff Trend/StaffTrendReport')));
const ReportResultDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.1 Overall Staff Trend/ReportResultDetails')));
const StaffTrendReportResultDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.1 Overall Staff Trend/StaffTrendReportResultDetails')));
const QuestionByStaff = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.2 Question By Staff/QuestionByStaff')));
const QuestionByStaffDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.2 Question By Staff/QuestionByStaffDetails')));
const AnswersByStaff = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.3 Answers By Staff/AnswersByStaff')));
const AnswersByStaffDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.3 Answers By Staff/AnswersByStaffDetails')));
const AnswersByStaffDistribution = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.3 Answers By Staff/AnswerByStaffDistribution')));
const OverallStaffTrendByIndivitual = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.4 Overall Trend By Individual/OverallStaffTrendByIndivitual')));
const ReportResultIndDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.4 Overall Trend By Individual/ReportResultIndDetails')));
const SectionTrendIndividualWrapper = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.5 Section Trend by Individual/section_trend_individual')));
const SectionDetailTable = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.8 setion_details_table')));
const QuestionTrendIndividual = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.6 Question Trend By Individual/QuestionTrendIndividual')));
const QuestionIndividualResultDetail = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.6 Question Trend By Individual/QuestionIndividualResultDetail')));
const SectionByResult = Loadable(lazy(() => import("../pages/dashboard/analytics/staff/1.7 Section by Result/section_by_result")));
const SectionByEventType = Loadable(lazy(() => import("../pages/dashboard/analytics/staff/1.8 section by event type/section_by_event_type")));
const KudosByStaff = Loadable(lazy(() => import("../pages/dashboard/analytics/staff/1.9 Kudos By Staff/KudosByStaff")));
const CausesByStaff = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.10/causes_by_staff')));
const AcceptanceAgentTable = Loadable(lazy(() => import('../pages/dashboard/analytics/staff/1.11 Result Acceptance By Agent/1.11 acceptance_by_agent')));
const StaffResultDetails = Loadable(lazy(() => import("../pages/dashboard/analytics/staff/result_details_staff")));


// Analytic Dashboard - Supervisor
const SupervisorBySection = Loadable(lazy(() => import('../pages/dashboard/analytics/supervisor/2.1 Supervisor By Section/SupervisorBySection')));
const PointOfFailureSupervisors = Loadable(lazy(() => import("../pages/dashboard/analytics/supervisor/2.2 Points Of Failure Supervisors.js/points_of_failure_supervisor")));
const SupervisorByQuestion = Loadable(lazy(() => import('../pages/dashboard/analytics/supervisor/2.3/SupervisorByQuestion')));
const SupervisorByQuestionDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/supervisor/2.3/SupervisorByQuestionDetails')));
const ResultAcceptenceBySupervisor = Loadable(lazy(() => import('../pages/dashboard/analytics/supervisor/2.4 Result Acceptance By Supervisor/ResultAceptenceBySupervisor/ResultAcceptenceBySupervisor')));
const SupervisorReportResultDetails = Loadable(lazy(() => import("../pages/dashboard/analytics/supervisor/result_details_supervisor")));
const SupervisorSectionDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/supervisor/2.1 Supervisor By Section/SupervisorSectionDetails')));
const StaffBySection = Loadable(lazy(() => import('../pages/dashboard/analytics/supervisor/2.1 Supervisor By Section/StaffBySection')));

// Analytic Dashboard - Evalution
const EvaluationPerScorecardWrapper = Loadable(lazy(() => import('../pages/dashboard/analytics/evaluation/3.3 evaluation_per_scorecard')));
const EvaluationPerScorecardEvaluator = Loadable(lazy(() => import('../pages/dashboard/analytics/evaluation/3.5 EvaluationPerCardByEvaluator/EvaluationPerSocreCardEvaluator')));
const EvaluationPerPeriod = Loadable(lazy(() => import('../pages/dashboard/analytics/evaluation/3.2 evaluationperperiod/evaluation_per_period')));
const EvaluationPerScorecardByStaff = Loadable(lazy(() => import('../pages/dashboard/analytics/evaluation/3.4 EvaluationPerScorecardByStaff/Evaluations_Per_Scorecard_By_Staff')));
const EvaluationReviewsBySupervisor = Loadable(lazy(() => import('../pages/dashboard/analytics/evaluation/ReviewBySupervisor3_7/reviewbysupervisor')));
const EvaluationReviewsByEvaluator = Loadable(lazy(() => import("../pages/dashboard/analytics/evaluation/ReviewsByEvaluator3_6/reviews_by_evaluator")));
const ScoringPatterns = Loadable(lazy(() => import("../pages/dashboard/analytics/evaluation/3.1 Scoring Patterns/ScoringPatterns")));
const EvaluationReportResultDetails = Loadable(lazy(() => import("../pages/dashboard/analytics/evaluation/result_details_evaluation")));

// Analytic Dashboard - Scorecards
const QuestionTrend = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.4 Question Trend/QuestionTrend')));
const OverAllTrends = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.1 Overall Trend/OverAllTrends')));
const OverAllTrendsResultDetail = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.1 Overall Trend/OverAllTrendsResultDetail')));
const SectionTrendReport = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.5 Section Trend/ScorecardsSectionTrend')));
const SetionTrendDetailsTable = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.5 Section Trend/SetionTrendDetailsTable')));
const CausesTrend = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.8/CausesTrend')));
const CausesTrendDetails = Loadable(lazy(() => import('src/pages/dashboard/analytics/scorecards/4.8/CausesTrendDetails')));
const CausesByScorecard = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesByScorecard')));
const CausesBySections = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesBySections')));
const CausesByQuestion = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesByQuestion')));
const CausesByIndQuestion = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesByIndQuestion')));
const CausesReportResultDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.7 Causes By Scorecard/CausesReportResultDetails')));
const EventTypeTrends = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.2 Event Type Trend/EventTypeTrend')));
const EventSubTypeTrends = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/4.2 Event Type Trend/EventSubTypeTrend')));
const PerformanceAgainstTarget = Loadable(lazy(() => import("../pages/dashboard/analytics/scorecards/4.3 Performance Against Target/PerformanceAgainstTarget")))
const PerformanceAgainstEventSubType = Loadable(lazy(() => import("../pages/dashboard/analytics/scorecards/4.3 Performance Against Target/PerformanceEventSubType")))
const ScoreCardResultDetailsPage = Loadable(lazy(() => import('../pages/dashboard/analytics/scorecards/ScoreCardResultDetailsPage')))
const ScoreCardComments = Loadable(lazy(() => import("../pages/dashboard/analytics/scorecards/4.6 Comment/comment_4_6")));
const QuestionDetailsComplianceByQuestions = Loadable(lazy(() => import("../pages/dashboard/analytics/scorecards/question_details_4_9")));
const ComplianceByQuestions = Loadable(lazy(() => import("../pages/dashboard/analytics/scorecards/4.9 Compliance By Questions/compliance_by_questions")));
const DataTagsByEventType = Loadable(lazy(() => import("../pages/dashboard/analytics/scorecards/4.10 Data Tags By Event Type/DataTagsByEventType")))
const CustomerRefByDataTags = Loadable(lazy(() => import("../pages/dashboard/analytics/scorecards/4.11 Customer Reference By Data Tags/customer_reference_data_tags")));

// const { AnalyticProviders } = Loadable(lazy(() => import('../contexts/AnalyticContext')));
// const SupervisorSectionDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/supervisor/2.1 Supervisor By Section/SupervisorSectionDetails')));
// const StaffBySection = Loadable(lazy(() => import('../pages/dashboard/analytics/supervisor/2.1 Supervisor By Section/StaffBySection')));
// const CustomDashboardTest = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/analytics/customDashboard')));
// const AcceptanceResultTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/analytics/acceptance_by_result')));

// Analytic Dashboard - Teams
const OverAllTeamsTrends = Loadable(lazy(() => import('../pages/dashboard/analytics/teams/5.1/OverAllTeamsTrends')));
const OverAllTeamsTrendsDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/teams/5.1/OverAllTeamsTrendsDetails')));
const TeamSupervisor = Loadable(lazy(() => import('../pages/dashboard/analytics/teams/5.1/TeamSupervisor')));
const StaffTrendsBySupervisor = Loadable(lazy(() => import('../pages/dashboard/analytics/teams/5.1/StaffTrendsBySupervisor')));
const StaffTrendsByStaffResult = Loadable(lazy(() => import('../pages/dashboard/analytics/teams/5.1/ResultDetails')));
const AcceptanceTeamTable = Loadable(lazy(() => import("../pages/components-overview/material-ui/table/analytics/acceptance_by_team")));
const PointsOfFailureByTeam = Loadable(lazy(() => import("../pages/dashboard/analytics/teams/5.2/PointsOfFailureByTeam")));
const PointsOfFailureByTeamByScorecard = Loadable(lazy(() => import("../pages/dashboard/analytics/teams/5.2/PointsOfFailureByTeamByScorecard")));
const PointsOfFailureReportResultDetails = Loadable(lazy(() => import("../pages/dashboard/analytics/teams/5.2/PointsOfFailureReportResultDetails")));

// const EvaluationReviewsBySupervisor = Loadable(lazy(() => import("../pages/dashboard/analytics/evaluation/ReviewBySupervisor3_7/reviewbysupervisor")));
const BillingDashboard = Loadable(lazy(() => import("../pages/dashboard/billing/billingdashboard")));
// const AcceptanceGroupTable = Loadable(lazy(() => import("../pages/components-overview/material-ui/table/analytics/groups/acceptance_by_group")));
// const DashboardLayout = Loadable(lazy(() => import("../layouts/dashboard/index")));
// const AnalyticProviders = Loadable(lazy(() => import("./default_name")));
// Analytic Dashboard - Group
const GroupSummary = Loadable(lazy(() => import("../pages/dashboard/analytics/groups/6.1 summary")));
const PointsOfFailure = Loadable(lazy(() => import("../pages/dashboard/analytics/groups/6.3 Points of Failure/point_of_failure")));
const SummaryByGroup = Loadable(lazy(() => import("../pages/dashboard/analytics/groups/6.1 summaryByGroup")));
const GroupReportResultDetails = Loadable(lazy(() => import("../pages/dashboard/analytics/groups/result_details_group")));
const ScoreCardCategories = Loadable(lazy(() => import('../pages/dashboard/analytics/groups/6.2 Scorecard Categories/ScorecardCategories')))
const ScorecardCategoriesDetails = Loadable(lazy(() => import('../pages/dashboard/analytics/groups/6.2 Scorecard Categories/ScorecardCategoriesDetails')))
const CalibrationTrend = Loadable(lazy(() => import('../pages/dashboard/analytics/groups/6.6 Calibrations Trend/CalibrationTrend')));
const CustomerReference = Loadable(lazy(() => import("../pages/dashboard/analytics/groups/6.4 Customer Reference/CustomerReference")));
const AdvanceAnalytics = Loadable(lazy(() => import("../pages/dashboard/analytics/Advance/advanceAnalytics")));
const ComplianceOverview = Loadable(lazy(() => import('../pages/dashboard/analytics/groups/6.5 Compliance Overview/ComplianceOverview')))
const ComplianceByTeam = Loadable(lazy(() => import('../pages/dashboard/analytics/groups/6.5 Compliance Overview/ComplianceByTeam')))
const ComplianceByStaff = Loadable(lazy(() => import('../pages/dashboard/analytics/groups/6.5 Compliance Overview/ComplianceByStaff')))
const ResultAcceptanceByGroup = Loadable(lazy(() => import('../pages/dashboard/analytics/groups/6.7 result_acceptance_by_group/ResultAcceptanceByGroup')))
const AdvanceResultDetails = Loadable(lazy(() => import("../pages/components-overview/material-ui/table/analytics/advance/advance_result_details")));
const HelpSupportIndex = Loadable(lazy(() => import("../pages/dashboard/help_support/help_support_index")));
const HelpSupportCreate = Loadable(lazy(() => import("../pages/dashboard/help_support/help_support_create_ticket")));
const HelpSupportView = Loadable(lazy(() => import("../pages/dashboard/help_support/help_support_view_ticket")));

// Evaluator Dashboard ---
const EvaluatorDashboardLayout = Loadable(lazy(() => import('../pages/evaluator_dashboard/EvaluatorDashboardLayout')))
// AI COMPONENTS
const CallsIndexTable = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/ai-table/calls/index')))
// const CallDetailsWrapper = Loadable(lazy(() => import('../layouts/dashboard/test')))
const CallDetailsWrapper = Loadable(lazy(() => import('../pages/ai-dashboard/calls/call_details_wrapper')))
// Moments Dashboard
const Moments = Loadable(lazy(() => import('../pages/dashboard/ai-dashboard/moments/MomentsDashboard')));
const ArchivedMoments = Loadable(lazy(() => import('../pages/dashboard/ai-dashboard/moments/ArchivedMoments')));
const MomentsCategory = Loadable(lazy(() => import('../pages/dashboard/ai-dashboard/moments/MomentsCategory')));
const EventBuilder = Loadable(lazy(() => import('../pages/dashboard/ai-dashboard/moments/EventBuilder')));
const AnalyzeIndex = Loadable(lazy(() => import('../pages/ai-dashboard/analyze/index')));
const AutoQARules = Loadable(lazy(() => import('../pages/components-overview/material-ui/table/ai-table/auto_qa/auto_qa_rules_table')));
const AutoQARuleForm = Loadable(lazy(() => import('../pages/ai-dashboard/auto qa/auto_qa_rules_form')));
const QAFormRule = Loadable(lazy(() => import('../pages/ai-dashboard/auto qa/qa_form')))
const AgentReport = Loadable(lazy(() => import('../pages/ai-dashboard/coaching/agent_report_index')));
const CreateCoachingSessionFormWrapper = Loadable(lazy(() => import('../pages/ai-dashboard/coaching/create_coaching_session_wrapper')));
const ViewCoachingSessionWrapper = Loadable(lazy(() => import('../pages/ai-dashboard/coaching/view_coaching_session_wrapper')));
const AddCoachingSessionWrapper = Loadable(lazy(() => import('../pages/ai-dashboard/coaching/Add_coaching_wrapper')));
const AssignmentIndex = Loadable(lazy(() => import(`../pages/ai-dashboard/assignment/assignmentIndex`)))
const AssignmentMasterConfig = Loadable(lazy(() => import(`../pages/ai-dashboard/assignment/master_config_assignment`)))
const AssignmentDetailsIndex = Loadable(lazy(() => import(`../pages/ai-dashboard/assignment/assignment_details_index`)));
const CoachingDashboardWrapper = Loadable(lazy(() => import(`../pages/ai-dashboard/coaching/coaching_dashboard_wrapper`)));
const Wordcloud = Loadable(lazy(() => import(`../pages/ai-dashboard/analyze/word_cloud`)));
const EventHeatMap = Loadable(lazy(() => import(`../pages/ai-dashboard/analyze/event_heat_map`)))
const EmpViewCoaching = Loadable(lazy(() => import(`../pages/employee/coaching`)));


