import React from 'react';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';

import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Button, Badge, Menu, MenuItem } from '@material-ui/core';
import { Mail, KeyboardArrowDown } from "@material-ui/icons";

// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import { SettingsContext } from "../../../contexts/SettingsContext";
import { EmpContext } from "../../../contexts/employeecontext";
import Logo from "../../../components/Logo";
// components
import { MHidden } from '../../../components/@material-extend';
import { PATH_EMP, PATH_DASHBOARD } from "../../../routes/paths";
// import Searchbar from './Searchbar';
import AccountPopover from '../AccountPopover';
import SpeechAiPopover from '../AccountPopover1';
import LanguagePopover from '../LanguagePopover';
import { getMessageCount } from "../../../_apis_/authApi.js/globalDashboardApi";


// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH_XL = 280;
const DRAWER_WIDTH_LG = 200;
const COLLAPSE_WIDTH_LG = 48;
const COLLAPSE_WIDTH_XL = 60;

// const APPBAR_MOBILE = 64;
const APPBAR_MOBILE = 34;
// const APPBAR_DESKTOP = 92;
const APPBAR_DESKTOP = 70;

const RootStyle = styled(AppBar)(({ theme, t, p, showSidebar }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('lg')]: {
        width: ((t === "score" && p?.split("/")[1] !== "notification" && p?.split("/")[1] !== "companySettings" && p?.split("/")[2] !== "reviewPerformance" && p?.split("/")[1] !== "score" && p?.split("/")[1] !== "userProfile") && p?.split("/")[1] !== "calibration") || !showSidebar ? "100%" : `calc(100% - ${DRAWER_WIDTH_LG}px)`
        // width: `calc(100% - ${DRAWER_WIDTH}px)`
    },
    [theme.breakpoints.up('xl')]: {
        width: ((t === "score" && p?.split("/")[1] !== "notification" && p?.split("/")[1] !== "companySettings" && p?.split("/")[2] !== "reviewPerformance" && p?.split("/")[1] !== "score" && p?.split("/")[1] !== "userProfile") && p?.split("/")[1] !== "calibration") || !showSidebar ? "100%" : `calc(100% - ${DRAWER_WIDTH_XL}px)`
        // width: `calc(100% - ${DRAWER_WIDTH}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------



const isScore = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Score Only,Score & Calibrate"
const isStaff = "Global Admin,Group Admin,Team Admin,Internal"
const isScorecard = "Global Admin,Group Admin,Internal"
const isAnalytics = "Global Admin,Group Admin,Team Admin,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics"
const isReports = "Global Admin,Group Admin,Team Admin,Internal,Score & Reports,(Score, Reports & Analytics),(Score, Calibrate, Report & Analytics),Reports & Analytics,Reports Only"
const isCalibrate = "Global Admin,Group Admin,Team Admin,Score & Calibrate,Calibrate Only,(Score, Calibrate, Report & Analytics)"
export default function EmployeeDashboardNavbar({ type }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const { pathname } = useLocation();
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const grpArr = loggedUserData?.groups?.map(obj => obj.id);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [messageCount, setMessageCount] = React.useState(0);
    const navigate = useNavigate();

    // console.log(loggedUserData)
    const { showHelpSupportPopUp,
        setNotificationMsgCount, notificationMsgCount,
        setShowHelpSupportPopUp,
        setColor, themeMode, showSidebar, isOpenSidebar, setIsOpenSidebar } = React.useContext(SettingsContext);
    const { activeMenu, setActiveMenu } = React.useContext(EmpContext);
    const { isCollapse } = useCollapseDrawer();
    React.useEffect(() => {
        async function getCount() {
            const { data } = await getMessageCount();
            setNotificationMsgCount(data?.data);
        }
        getCount();
    }, [pathname, notificationMsgCount])

    const themeColor = () => {
        if (themeMode === 'light') {
            return setColor?.main
        }
        return setColor?.dark
    }

    return (
        <RootStyle
            t={type}
            p={pathname}
            showSidebar={showSidebar}
            sx={{
                ...((isCollapse && showSidebar) && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH_LG}px)`, xl: `calc(100% - ${COLLAPSE_WIDTH_XL}px)` }
                })
            }}
        >

            {(loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "#ecfefff3", width: "100%", height: "50px" }}>
                <Link to={PATH_DASHBOARD.general.dashboard} style={{ fontSize: "12px" }}>Back To supervisor Dashboard</Link>
            </Box>}
            {searchParams.get("global_dash") === `True` && <Box display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "#ecfefff3", width: "100%", height: "50px" }}>
                <Link to={PATH_DASHBOARD.general.dashboard} style={{ fontSize: "12px" }}>Back To Global Dashboard</Link>
            </Box>}
            <ToolbarStyle>
                <MHidden width="lgUp">
                    <IconButton onClick={() => setIsOpenSidebar(true)} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden>
                {!showSidebar && <Link to={!loggedUserData?.staff?.staffType ? "/dashboard" : "/employee/dashboard"}>
                    <Box sx={{ mr: 5, display: { xs: 'none', lg: 'block' }, width: { xs: '160px', lg: '100%' } }}><Logo /></Box>
                </Link>}
                {(type === "score" && pathname.split("/")[1] !== 'auto_qa_rules' && pathname.split("/")[1] !== 'calls' && pathname.split("/")[1] !== 'events' && pathname.split("/")[1] !== 'analyze' && pathname.split("/")[1] !== 'coaching' && pathname.split("/")[1] !== "notification" && pathname.split("/")[1] !== "calibration" && pathname.split("/")[1] !== "companySettings" && pathname?.split("/")[2] !== "reviewPerformance" && pathname?.split("/")[1] !== "score" && pathname?.split("/")[1] !== "userProfile") && <Logo />}
                {/* <Searchbar /> */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }} >
                    {(searchParams.get("global_dash") === 'True' || loggedUserData?.staff?.staffType === 10) && <Link to={`${PATH_DASHBOARD.general.dashboard}`} style={{ textDecoration: "none" }}>
                        <Button disableRipple sx={{ mx: 2, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: (pathname.split("/")[1] === "employee" || pathname.split("/")[1] === "supervisor") ? themeColor() : "transparent" }, color: (pathname.split("/")[1] === "employee" || pathname.split("/")[1] === "supervisor") ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                            <Icon
                                sx={{
                                    fontSize: { lg: '16px', xl: '18px' },
                                    mr: 1,
                                    color: "inherit" // Ensures the icon color matches the text color
                                }}
                            >

                                <img alt="Custom SVG Icon" src="/static/illustrations/navbar/dashboard.svg" width="24" height="24" />

                            </Icon>
                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Dashboard</Typography>
                        </Button>
                    </Link>}
                    {loggedUserData?.staff?.isSelfScore && <Link to={`/score/select_scorecard?grpId=${grpArr.join("")}&empId=${loggedUserData?.staff?.id}&sup_dash=False`} style={{ textDecoration: "none" }}>
                        <Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === "/score/select_scorecard" ? themeColor() : "transparent" }, color: pathname === "/score/select_scorecard" ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                            <Icon
                                sx={{
                                    fontSize: { lg: '16px', xl: '18px' },
                                    mr: 1,
                                    color: "inherit" // Ensures the icon color matches the text color
                                }}
                            >

                                <img alt="Custom SVG Icon" src="/static/illustrations/navbar/staff.svg" width="24" height="24" />

                            </Icon>
                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Self Score</Typography>
                        </Button>
                    </Link>}
                    {/* {loggedUserData?.staff?.staffType === 10 && <Link to="/calibration" style={{ textDecoration: "none" }}>
                        <Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("calibration") ? themeColor() : "transparent" }, color: pathname.includes("calibration") ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>

                            <Icon
                                sx={{
                                    fontSize: { lg: '16px', xl: '18px' },
                                    mr: 1,
                                    color: "inherit" // Ensures the icon color matches the text color
                                }}
                            >

                                <img alt="Custom SVG Icon" src="/static/illustrations/navbar/calibration.svg" width="24" height="24" />

                            </Icon>
                            <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Calibration</Typography>

                        </Button>
                    </Link>} */}



                    {((loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30)) && <Link to={`/score/select_emp?id=${grpArr.join(",")}&sup_dash=True`} style={{ textDecoration: "none" }}><Button disableRipple onClick={() => { setActiveMenu(3) }} sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/score.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Score</Typography>
                    </Button></Link>}


                    {(!showSidebar && loggedUserData?.staff?.staffType === 10) && <Link to={searchParams.get("stff_emp_id") ? `${PATH_EMP?.scores}?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=${searchParams.get("global_dash")}` : PATH_EMP?.scores} style={{ textDecoration: "none" }}><Button disableRipple onClick={() => { setActiveMenu(3) }} sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/score.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Score</Typography>
                    </Button></Link>}


                    {((!showSidebar && loggedUserData?.staff?.staffType === 10)) && <Link to={searchParams.get("stff_emp_id") ? `${PATH_EMP?.tasks}?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=${searchParams.get("global_dash")}` : PATH_EMP?.tasks} style={{ textDecoration: "none" }}><Button disableRipple onClick={() => { setActiveMenu(3) }} sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/scorecard.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Tasks</Typography>
                    </Button></Link>}
                    {((!showSidebar && loggedUserData?.staff?.staffType === 10)) && <Link to={searchParams.get("stff_emp_id") ? `${PATH_EMP?.comments}?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=${searchParams.get("global_dash")}` : PATH_EMP?.comments} style={{ textDecoration: "none" }}><Button disableRipple onClick={() => { setActiveMenu(3) }} sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/reports.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Comments</Typography>
                    </Button></Link>}
                    {((!showSidebar && loggedUserData?.staff?.staffType === 10)) && <Link to={searchParams.get("stff_emp_id") ? `${PATH_EMP?.activity}?stff_emp_id=${searchParams.get("stff_emp_id")}&global_dash=${searchParams.get("global_dash")}` : PATH_EMP?.activity} style={{ textDecoration: "none" }}><Button disableRipple onClick={() => { setActiveMenu(3) }} sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/analytics.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Activities</Typography>
                    </Button></Link>}
                    {((!showSidebar && loggedUserData?.staff?.staffType === 10)) && <Link to={`/score/select_emp?id=${grpArr.join(",")}&sup_dash=True`} style={{ textDecoration: "none" }}><Button disableRipple onClick={() => { setActiveMenu(3) }} sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/score.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Goals</Typography>
                    </Button></Link>}
                    {(searchParams.get("global_dash") === 'True' && isScore.includes(loggedUserData?.userLevel?.name)) && <Link to={PATH_DASHBOARD?.general?.score} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/score.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Score</Typography>
                    </Button>
                    </Link>}
                    {((searchParams.get("global_dash") === 'True' && isStaff.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={PATH_DASHBOARD?.general?.Staff} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}
                    >
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/staff.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Staff</Typography>
                    </Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isScorecard.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 30) && <Link to={PATH_DASHBOARD?.general?.Scorecards} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/scorecard.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Scorecard</Typography>
                    </Button></Link>}
                    {(searchParams.get("global_dash") === 'True' && isScorecard.includes(loggedUserData?.userLevel?.name)) && <Link to={PATH_DASHBOARD?.general?.admin} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/admin.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Admin</Typography>
                    </Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isReports.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to={PATH_DASHBOARD?.general?.reports} style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "transparent" }, color: pathname === PATH_DASHBOARD?.general?.Staff ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/reports.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Reports</Typography>
                    </Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isCalibrate.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to="/calibration" style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("calibration") ? themeColor() : "transparent" }, color: pathname === pathname.includes("calibration") ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/calibration.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Calibration</Typography>
                    </Button></Link>}
                    {((searchParams.get("global_dash") === 'True' && isAnalytics.includes(loggedUserData?.userLevel?.name)) || loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) && <Link to="/calibration" style={{ textDecoration: "none" }}><Button disableRipple sx={{ mx: { xs: 1, xl: 2 }, fontSize: { xs: '12px', xl: '14px' }, position: "relative", "&:after": { content: '""', top: "100%", position: "absolute", width: "100%", height: "3px", backgroundColor: pathname.includes("calibration") ? themeColor() : "transparent" }, color: pathname === pathname.includes("calibration") ? themeColor() : "gray", "&:hover": { backgroundColor: "transparent", color: themeColor() } }}>
                        <Icon
                            sx={{
                                fontSize: { lg: '16px', xl: '18px' },
                                mr: 1,
                                color: "inherit" // Ensures the icon color matches the text color
                            }}
                        >

                            <img alt="Custom SVG Icon" src="/static/illustrations/navbar/analytics.svg" width="24" height="24" />

                        </Icon>
                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold', pl: 1 }} >Analytics</Typography>
                    </Button></Link>}
                    {(!loggedUserData?.staff && loggedUserData?.isAiAccess && loggedUserData?.aiEnabled) && <SpeechAiPopover fromDashboard={false} />}


                </Box>
                <Stack sx={{ flexGrow: { xs: 1, sm: 0 } }} direction="row" alignItems="center" justifyContent='flex-end' spacing={{ xs: 0.5, sm: 1.5 }}>

                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover /> */}
                    {/* <ContactsPopover /> */}
                    {/* {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <IconButton onClick={() => { navigate("/notification") }}> <Badge badgeContent={messageCount} color="primary"><Mail /></Badge></IconButton>} */}
                    {loggedUserData?.userLevel?.name !== 'Dashboard Only' && <IconButton sx={{ mr: 1 }} onClick={() => { navigate("/notification"); }}> <Badge badgeContent={notificationMsgCount} color="primary"><Icon
                        sx={{
                            fontSize: { lg: '16px', xl: '18px' }
                        }}
                    >
                        <img alt="Custom SVG Icon" src="/static/illustrations/navbar/notification.svg" width="30" height="30" />
                    </Icon></Badge></IconButton>}

                    <AccountPopover />
                </Stack>

            </ToolbarStyle>

            <Box>
                <Menu
                    open={open}
                    onClose={() => handleCloseMenu()}
                    anchorEl={anchorEl}
                    autoFocus={false}
                    sx={{ border: '1px solid transparent', boxShadow: 0, minWidth: '150px' }}
                >
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/events') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Events</Typography>
                    </MenuItem>
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/calls') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Calls</Typography>
                    </MenuItem>
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/analyze') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Analyze</Typography>
                    </MenuItem>
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/auto_qa_rules') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Auto QA</Typography>
                    </MenuItem>
                    <MenuItem sx={{ minWidth: '150px' }} onClick={() => { handleCloseMenu(); navigate('/coaching/add_coaching?csId=AC') }}>

                        <Typography sx={{ fontSize: { lg: '12px', xl: '14px' }, fontWeight: 'bold' }} >Coaching</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </RootStyle >
    );
}
