// TYPES IMPORTS
import {
    GetSaff, GetGroups, GetTeams,
    AddStaff, DelStaff, EditStaff, AddSup, RestoreStaff,
    GetTasks, NewTask, EditTask,
    AddTeams, EditTeams, DelTeams,
    AddGroup, EditGroup, DelGroup,
    getGoals, AddGoal, EditGoal, DelGoal,
    GetEmps, AddEmps, EditEmps, DelEmps, AccessEmp,
    GetSups, AddSups, EditSups, DelSups, AccessSup,
    listAllTypeSup, listAllTypeGrp, listAllTypeTeam
} from '../types/typeStaff';
// API IMPORTS
import { listGroupApi, listAllTeamApi, listSupervisorApi, listTeamApi, getGrpByEmpApi, filterEmpApi, filterSupApi, SearchStaffApi, getSupEmpApi, allEmpApi, allSupApi, SearchEmpApi, SearchRestoreApi, SearchSupApi, getAllGroupsApi2, getAllGroupsApi, getAllTeamsApi, SearchTeamApi, restoreStaffApi, SearchGroupApi, SearchEmpWithGroupTeamApi } from '../../_apis_/authApi.js/staffApi';
import { empTaskApi } from '../../_apis_/authApi.js/empapi';

// ---------*STAFF ACTION (emp & supervisor)*-------------


export const searchAllStaff = (query, pgNo) =>

    async (dispatch) => {

        const { data } = await SearchStaffApi(query, pgNo)
        const newArr = []
        data?.results?.map(obj => { if (obj?.user?.is_deleted === false) { return newArr.push(obj) } return obj });
        // const count = data?.count
        return dispatch(setAllStaff({ results: newArr, count: newArr?.length }))
        // return dispatch(setAllStaff({ results: newArr, count: newArr?.length, page_range: data?.page_range }))
    }

export const getAllStaff = (id, pgNo, query) =>
    async (dispatch) => {
        const { data } = await getGrpByEmpApi(id, pgNo, query);
        dispatch(setAllStaff(data))
    }



const setAllStaff = (data = null) => {
    if (data) {
        return {
            type: GetSaff,
            payload: data
        };
    }
    return {
        type: GetSaff,
        payload: data
    }
}


export const setNewStaff = (data = null) => {
    if (data) {
        return {
            type: AddStaff,
            payload: data
        };
    }
    return {
        type: AddStaff,
        payload: data
    }

}

export const editStaff = (data = null) => {
    if (data) {
        return {
            type: EditStaff,
            payload: data
        };
    }
    return {
        type: EditStaff,
        payload: data
    }

}

export const delStaff = (data = null) => {
    if (data) {
        return {
            type: DelStaff,
            payload: data
        };
    }
    return {
        type: DelStaff,
        payload: data
    }

}
export const addSup = (data = null) => {
    if (data) {
        return {
            type: AddSup,
            payload: data
        };
    }
    return {
        type: AddSup,
        payload: data
    }

}



// ------------------------------------*LIST SUPERVISOR*---------------------------------------




// -------------------------*employee*------------------------------

export const searchAllEmp = (query, pgNo) =>

    async (dispatch) => {
        const { data } = await SearchEmpApi(query, pgNo)
        const newArr = []
        data?.results?.map(obj => {
            if (obj?.user?.is_deleted === false) { return newArr.push(obj) } return obj
        });
        // const count = data?.count
        // return dispatch(setAllEmp(newArr))
        // return dispatch(setAllEmp({ results: newArr, count: newArr?.length }))
        return dispatch(setAllEmp({ results: newArr, count: data?.count, page_range: data?.page_range }))

    }

export const getAllEmp = (id) => {
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    return async (dispatch) => {
        if (loggedUserData?.staff?.staffType === 20 || loggedUserData?.staff?.staffType === 30) {
            const { data } = await getSupEmpApi(id);

            dispatch(setAllEmp(data))
        }
        else {
            const { data } = await allEmpApi(id);
            // const { results, count, pageRange } = humps.camelizeKeys(res.data);
            dispatch(setAllEmp(data))
        }

    }
}

export const filterEmp = (grp, tm, pgNo) => {
    return async (dispatch) => {
        const { data } = await filterEmpApi(grp, tm, pgNo)
        dispatch(setAllEmp(data))
    }
}

export const SearchEmpWithGroupTeam = (query, grp, tm, pgNo) => {
    return async (dispatch) => {
        const { data } = await SearchEmpWithGroupTeamApi(query, grp, tm, pgNo)
        dispatch(setAllEmp(data))
    }
}


const setAllEmp = (data = null) => {
    if (data) {
        return {
            type: GetEmps,
            payload: data
        };
    }
    return {
        type: GetEmps,
        payload: data
    }
}

export const setAccessEmp = (data = null) => {
    if (data) {
        return {
            type: AccessEmp,
            payload: data
        };
    }
    return {
        type: AccessEmp,
        payload: data
    }
}

export const newEmp = (data = null) => {
    if (data) {
        return {
            type: AddEmps,
            payload: data
        };
    }
    return {
        type: AddEmps,
        payload: data
    }

}

export const editEmp = (data = null) => {
    if (data) {
        return {
            type: EditEmps,
            payload: data
        };
    }
    return {
        type: EditEmps,
        payload: data
    }

}

export const delEmp = (data = null) => {
    if (data) {
        return {
            type: DelEmps,
            payload: data
        };
    }
    return {
        type: DelEmps,
        payload: data
    }

}

export const getAllSup = (id) =>
    async (dispatch) => {
        const { data } = await allSupApi(id);
        dispatch(setAllSup(data))
    }


export const filterSup = (grp, tm, pgNo) => {
    return async (dispatch) => {
        const { data } = await filterSupApi(grp, tm, pgNo)
        dispatch(setAllSup(data))
    }
}

export const searchAllSup = (query, pgNo) =>

    async (dispatch) => {

        const { data } = await SearchSupApi(query, pgNo)
        const newArr = []
        data?.results?.map(obj => { if (obj?.user?.is_deleted === false) { return newArr.push(obj) } return obj });
        // const count = data?.count
        // return dispatch(setAllSup({ results: newArr, count: newArr?.length }))
        return dispatch(setAllSup({ results: newArr, count: data?.count, page_range: data?.page_range }))

    }

export const setAccessSup = (data = null) => {
    if (data) {
        return {
            type: AccessSup,
            payload: data
        };
    }
    return {
        type: AccessSup,
        payload: data
    }
}

const setAllSup = (data = null) => {
    if (data) {
        return {
            type: GetSups,
            payload: data
        };
    }
    return {
        type: GetSups,
        payload: data
    }
}

export const newSup = (data = null) => {
    if (data) {
        return {
            type: AddSups,
            payload: data
        };
    }
    return {
        type: AddSups,
        payload: data
    }

}

export const editSup = (data = null) => {
    if (data) {
        return {
            type: EditSups,
            payload: data
        };
    }
    return {
        type: EditSups,
        payload: data
    }

}

export const delSup = (data = null) => {
    if (data) {
        return {
            type: DelSups,
            payload: data
        };
    }
    return {
        type: DelSups,
        payload: data
    }

}


//  ----------*TASK ACTIONS*--------------

export const empTaskDashboard = (date1, date2, id) => async (dispatch) => {
    const { data } = await empTaskApi(date1, date2, id);

    dispatch(setTasks(data));
}


export const setTasks = (data = null) => {
    if (data) {
        return {
            type: GetTasks,
            payload: data
        };
    }
    return {
        type: GetTasks,
        payload: data
    }

}
export const newTasks = (data = null) => {
    if (data) {
        return {
            type: NewTask,
            payload: data
        };
    }
    return {
        type: NewTask,
        payload: data
    }

}
export const editTasks = (data = null) => {

    if (data) {
        return {
            type: EditTask,
            payload: data
        };
    }
    return {
        type: EditTask,
        payload: data
    }

}

// -----------*TEAMS ACTIONS*--------------

export const getAllTeams = (id) => async (dispatch) => {
    const { data } = await getAllTeamsApi(id);
    dispatch(setAllTeams(data))
}

export const searchAllTeams = (query, pgNo) => async (dispatch) => {
    const { data } = await SearchTeamApi(query, pgNo);
    dispatch(setAllTeams(data))
}


const setAllTeams = (data = null) => {
    if (data) {
        return {
            type: GetTeams,
            payload: data
        };
    }
    return {
        type: GetTeams,
        payload: data
    }

}


export const newTeam = (data = null) => {
    if (data) {
        return {
            type: AddTeams,
            payload: data
        };
    }
    return {
        type: AddTeams,
        payload: data
    }

}


export const editTeam = (data = null) => {
    if (data) {
        return {
            type: EditTeams,
            payload: data
        };
    }
    return {
        type: EditTeams,
        payload: data
    }

}


export const delTeam = (data = null) => {
    if (data) {
        return {
            type: DelTeams,
            payload: data
        };
    }
    return {
        type: DelTeams,
        payload: data
    }

}



// --------*Group ACTIONS*---------------


export const getAllGroups = (id, isApi2, query) => async (dispatch) => {

    if (isApi2) {
        const { data } = await getAllGroupsApi2(id, query);
        dispatch(setAllGroups(data))
    }
    if (!isApi2) {
        const { data } = await getAllGroupsApi(id);
        dispatch(setAllGroups(data))
    }

    // const { data } = await getAllGroupsApi(id);
    // dispatch(setAllGroups(data))


}

export const searchAllGroups = (query, pgNo) => async (dispatch) => {
    const { data } = await SearchGroupApi(query, pgNo);
    dispatch(setAllGroups(data))
}



const setAllGroups = (data = null) => {
    if (data) {
        return {
            type: GetGroups,
            payload: data
        };
    }
    return {
        type: GetGroups,
        payload: data
    }

}

export const newGroup = (data = null) => {
    if (data) {
        return {
            type: AddGroup,
            payload: data
        };
    }
    return {
        type: AddGroup,
        payload: data
    }

}
export const editGroup = (data = null) => {
    if (data) {
        return {
            type: EditGroup,
            payload: data
        };
    }
    return {
        type: EditGroup,
        payload: data
    }

}

export const delGroup = (data = null) => {
    if (data) {
        return {
            type: DelGroup,
            payload: data
        };
    }
    return {
        type: DelGroup,
        payload: data
    }

}


// --------------*GOAL ACTIONS*-----------------------
export const getAllGoals = (data = null) => {
    if (data) {
        return {
            type: getGoals,
            payload: data
        };
    }
    return {
        type: getGoals,
        payload: data
    }

}
export const newGoals = (data = null) => {
    if (data) {
        return {
            type: AddGoal,
            payload: data
        };
    }
    return {
        type: AddGoal,
        payload: data
    }

}
export const editGoals = (data = null) => {
    if (data) {
        return {
            type: EditGoal,
            payload: data
        };
    }
    return {
        type: EditGoal,
        payload: data
    }

}
export const delGoals = (data = null) => {
    if (data) {
        return {
            type: DelGoal,
            payload: data
        };
    }
    return {
        type: DelGoal,
        payload: data
    }

}


//   --------------------*Restore*-----------------------------
export const restoreStaff = (id, pg, isSupDashboard) =>
    async (dispatch) => {
        const { data } = await restoreStaffApi(id, pg, isSupDashboard)
        dispatch(setRestoreStaff(data));

    }

export const searchRestoreStaff = (id, query, pgNo) =>
    async (dispatch) => {
        const { data } = await SearchRestoreApi(id, query, pgNo)
        dispatch(setRestoreStaff(data));

    }


export const setRestoreStaff = (data = null) => {
    if (data) {
        return {
            type: RestoreStaff,
            payload: data
        };
    }
    return {
        type: RestoreStaff,
        payload: data
    }
}


// LIST ALL SUPERVISOR

export const getListAllSup = () =>
    async (dispatch) => {
        const { data } = await listSupervisorApi();
        dispatch(setListAllSup(data?.data));
    }


export const setListAllSup = (data = null) => {
    if (data) {
        return {
            type: listAllTypeSup,
            payload: data
        };
    }
    return {
        type: listAllTypeSup,
        payload: data
    }
}


export const getListAllGrp = () =>
    async (dispatch) => {
        const { data } = await listGroupApi();
        dispatch(setListAllGrp(data?.data))
    }


export const setListAllGrp = (data = null) => {
    if (data) {
        return {
            type: listAllTypeGrp,
            payload: data
        };
    }
    return {
        type: listAllTypeGrp,
        payload: data
    }
}


export const getListAllTeam = () =>
    async (dispatch) => {
        const { data } = await listAllTeamApi();
        dispatch(setListAllTeam(data?.results))
    }


export const setListAllTeam = (data = null) => {
    if (data) {
        return {
            type: listAllTypeTeam,
            payload: data
        };
    }
    return {
        type: listAllTypeTeam,
        payload: data
    }
}

// export const getListAllSup = (id) =>
//     async (dispatch)=> {
//         const { data } = await listSupervisorApi();

//     }


// export const setListAllSup = (data = null) => {
//     if (data) {
//         return {
//             type: listAllTypeSup,
//             payload: data
//         };
//     }
//     return {
//         type: listAllTypeSup,
//         payload: data
//     }
// }