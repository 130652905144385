import { useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@material-ui/core/styles';
import MainFooter from '../../main/MainFooter';
// import context 
import { SettingsContext } from "../../../contexts/SettingsContext"
// hooks
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
//
import EmployeeDashboardNavbar from './emp_navbar';
import EmployeeDashboardSidebar from './emp_sidenavbar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    backgroundColor: theme.palette.mode === 'light' ? '#f5f6fa' : '#636e72',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        // paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------

export default function EmployeeDashboardLayout() {
    const theme = useTheme();
    const { collapseClick } = useCollapseDrawer();
    const [open, setOpen] = useState(false);
    const { showHelpSupportPopUp, setShowHelpSupportPopUp, showSidebar, isOpenSidebar, setIsOpenSidebar } = useContext(SettingsContext)

    return (
        <>
            <RootStyle>
                <EmployeeDashboardNavbar />
                <EmployeeDashboardSidebar />
                <MainStyle
                    sx={{
                        transition: theme.transitions.create('margin', {
                            duration: theme.transitions.duration.complex
                        }),
                        ...(collapseClick && {
                            ml: !showSidebar ? '1px' : '60px'
                        })
                    }}
                >
                    <Outlet />
                </MainStyle>
            </RootStyle>
            <MainFooter />
        </>
    );
}
